import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const UserOrStylistRoute = ({ render, path }) => {
  const { isLogged } = useSelector(state => state.user);
  return isLogged ? (
    <Route exact path={path} render={render} />
  ) : (
    <Redirect to="/employees" />
  );
};

UserOrStylistRoute.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
