import React, { useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { useFetch, useTitle } from "../../../utils/hooks";
import { useSelector } from "react-redux";
import { Employee } from "./employee/Employee";
import { Loading, Error } from "../../parts/PageStatuses";
import "./Employees.css";
import { AddModal } from "./modal/Modal";
import { Outsider } from "../../util_comps/Wrappers";
import { FaButton } from "../../parts/FaButton";

const Employees = () => {
  const { data, loading, error, refresh } = useFetch(
    "/api/v1/stylist/fetch/all",
    []
  );
  const [add, setAdd] = useState(false);
  const gold = useSelector(state => state.manager.gold);
  useTitle("კადრები");
  return (
    <div className="grid-page page">
      <Navigation active="/employees" />
      <div className="employees-page-content">
        {!loading && !error ? (
          <FaButton
            icon="faUser"
            className="add-stylist big-font"
            onClick={() => setAdd(true)}
          >
            კადრის დამატება
          </FaButton>
        ) : null}
        {data
          .filter(item => item.gold === gold)
          .map((item, i) => {
            return (
              <Employee
                key={i}
                intern={item.intern}
                name={item.name}
                isFree={item.isFree}
                id={item._id}
                salary={item.salary}
                services={item.services}
                profession={item.profession}
                refresh={refresh}
              />
            );
          })}
        {loading ? <Loading /> : null}
        {error ? <Error /> : null}
        {add ? (
          <Outsider onOutside={() => setAdd(false)}>
            <AddModal refresh={refresh} update={false} />
          </Outsider>
        ) : null}
      </div>
    </div>
  );
};

export default Employees;
