import React from "react";
import Slider from "react-slick";
import { useFetch } from "../../utils/hooks";
import { WorkItem } from "./WorkItem";
import "../../styles/lastWork.css";

export const LastWork = () => {
  const { data } = useFetch("/api/v1/work/recents", []);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: Math.min(data.length, 4),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(data.length, 3),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(data.length, 2),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(data.length, 1),
        },
      },
    ],
    slidesToScroll: 1,
  };

  return (
    <div className="lastwork-section">
      <div className="popular-stylist-header">
        <h2>ბოლო ნამუშევრები</h2>
        <div className="white border"></div>
      </div>
      <Slider {...settings}>
        {data.map((item, i) => {
          return (
            <WorkItem
              key={i}
              photo={`/api/v1/statics/stylist/work/${item.stylist._id}/${item.work}`}
              name={item.stylist.name}
              id={item.stylist._id}
              stylistPhoto={
                item.stylist.photo
                  ? `/api/v1/statics/stylist/photo/${item.stylist._id}`
                  : require("../../assets/placeholder.png")
              }
            />
          );
        })}
      </Slider>
    </div>
  );
};
