export const work = {
  "მანიკურის სპეციალისტი": [
    { name: "ნუნის დამუშავება", price: 6 },
    { name: "ნუნა + მანიკური", price: 10 },
    { name: "ნუნის დამუშავება (აპარატით)", price: 8 },
    { name: "ნუნის დამუშავება (აპარატით)+მანიკური", price: 12 },
    { name: "ნუნა შილაქით", price: 15 },
    { name: "ნუნა შილაქით ლუქსი", price: 20 },
    { name: "ნუნა შილაქით ლუქსი + აკრილი", price: 25 },
    { name: "დაგრძელება", price: 50 },
    { name: "დაგრძელება ლუქსი", price: 55 },
    { name: "დაგრძელების (კორექცია)", price: 35 },
    { name: "ქრომის დამატება", price: 5 },
    { name: "აკრილით ან გელით გამაგრების დამატება", price: 5 },
    { name: "პედიკური სრული", price: 35 },
    { name: "პედიკური ლუქსი", price: 40 },
    { name: "პედიკური ფრჩხილი + ლუქსი", price: 45 },
    { name: "პედიკური კლასიკური", price: 30 },
    { name: "პედიკური ფეხის მოწესრიგება", price: 25 },
  ],
  "ვიზაჟის სპეციალისტი": [
    { name: "წარბი (ქალის)", price: 6 },
    { name: "წარბი (ბიჭის)", price: 8 },
    { name: "წამწამი 1 ხაზი", price: 5 },
    { name: "წამწამი მრავალჯერადი", price: 5 },
    { name: "თვალის ლაინერი ", price: 3 },
    { name: "თვალის მოხატვა", price: 5 },
    { name: "სახის დაფარვა", price: 5 },
    { name: "საღამოს მაკიაჟი", price: 0 },
    { name: "საქორწინო მაკიაჟი", price: 25 },
    { name: "ყურის გახვრეტა", price: 10 },
    { name: "ცხვირის გახვრეტა", price: 7 },
    { name: "ხრტილის გახვრეტა", price: 7 },
  ],
  სტილისტი: [{ name: "თმის შეჭრა", price: 0 }],
  კოსმეტოლოგი: [{ name: "კოსმეტოლოგის მომსახურება", price: 0 }],
};

export const proffesions = [
  "სტილისტი",
  "მანიკურის სპეციალისტი",
  "ვიზაჟის სპეციალისტი",
  "კოსმეტოლოგი",
];

export const hours = {
  0: {
    h: 5,
    m: 0
  },
  1: {
    h: 5,
    m: 30
  },
  2: {
    h: 6,
    m: 0
  },
  3: {
    h: 6,
    m: 30
  },
  4: {
    h: 7,
    m: 0
  },
  5: {
    h: 7,
    m: 30
  },
  6:{
    h: 8,
    m: 0
  },
  7: {
    h: 8,
    m: 30
  },
  8: {
    h: 9,
    m: 0,
  },
  9: {
    h: 9,
    m: 30,
  },
  10: {
    h: 10,
    m: 0,
  },
  11: {
    h: 10,
    m: 30,
  },
  12: {
    h: 11,
    m: 0,
  },
  13: {
    h: 11,
    m: 30,
  },
  14: {
    h: 12,
    m: 0,
  },
  15: {
    h: 12,
    m: 30,
  },
  16: {
    h: 13,
    m: 0,
  },
  17: {
    h: 13,
    m: 30,
  },
  18: {
    h: 14,
    m: 0,
  },
  19: {
    h: 14,
    m: 30,
  },
  20: {
    h: 15,
    m: 0,
  },
  21: {
    h: 15,
    m: 30,
  },
  22: {
    h: 16,
    m: 0,
  },
  23: {
    h: 16,
    m: 30,
  },
  23: {
    h: 17,
    m: 0,
  },
  24: {
    h: 17,
    m: 30,
  },
  25: {
    h: 18,
    m: 0,
  },
  26: {
    h: 18,
    m: 30,
  },
  27: {
    h: 19,
    m: 0,
  },
  28: {
    h: 19,
    m: 30,
  },
  29: {
    h: 20,
    m: 0,
  },
  30: {
    h: 20,
    m: 30,
  },
  31: {
    h: 21,
    m: 0,
  },
  32: {
    h: 21,
    m: 30,
  },
  33: {
    h: 22,
    m: 0,
  },
  34: {
    h: 22,
    m: 30,
  },
  35: {
    h: 23,
    m: 0,
  },
};

export const months = [
  "იანვარი",
  "თებერვალი",
  "მარტი",
  "აპრილი",
  "მაისი",
  "ივნისი",
  "ივლისი",
  "აგვისტო",
  "სექტემბერი",
  "ოქტომბერი",
  "ნოემბერი",
  "დეკემბერი",
];

export const addresses = ["სტალინის 20.", "სტალინის 14.", "სტალინის 2."];
