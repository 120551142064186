import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaButton } from "../../parts/FaButton";
import { Search } from "../../util_comps/Search";
import { Navigation } from "../navigation/Navigation";
import { TotalSwitch } from "../total/TotalSwitch";
import { AddBook } from "../users/addBook/AddBook";
import { Book } from "./Book";
import "./Books.css";

const Books = () => {
  useEffect(() => {
    document.title = "ჯავშნები";
  }, []);
  const [type, setType] = useState(0);
  const [books, setBooks] = useState([]);
  const [add, setAdd] = useState(false);
  const [r, setR] = useState(false);
  const [search, setSearch] = useState("");
  const gold = useSelector(state => state.manager.gold);
  useEffect(() => {
    (async () => {
      const res = await fetch(
        `/api/v1/booking/fetch/manager?gold=${gold}&approved=${
          type ? "true" : "false"
        }`
      );
      if (res.ok) {
        const data = await res.json();
        setBooks(data);
      }
    })();
  }, [gold, type, r]);
  const refresh = () => setR(!r);
  return (
    <div className="manager-books-page page">
      <Navigation active="/manager/bookings" />
      {add ? (
        <AddBook
          name=""
          number=""
          onClose={() => setAdd(false)}
          refresh={refresh}
        />
      ) : null}
      <div className="manager-bookings-list">
        <div className="centered">
          <FaButton
            className="big-font"
            onClick={() => setAdd(true)}
            icon="faBookmark"
          >
            ჯავშნის დამატება
          </FaButton>
        </div>
        <TotalSwitch
          textes={["მოთხოვნები", "ჯავშნები"]}
          setStatus={setType}
          status={type}
        />
        <Search
          placeholder="ძიება"
          value={search}
          onChange={e => setSearch(e.target.value)}
          onSearch={()=>{}}
        />
        <table>
          <tbody>
            <tr className="tr-header">
              <th>მენეჯერი</th>
              <th>მომხმარებელი</th>
              <th>ტელეფონი</th>
              <th>სტილისტი</th>
              <th>სერვისი</th>
              <th>ფასი</th>
              <th>თარიღი</th>
            </tr>
            {books
              .filter(item => {
                return JSON.stringify(item).includes(search);
              })
              .sort((a, b) => (a.bookedAt > b.bookedAt) ? 1 : -1)
              .map((item, i) => {
                return (
                  <Book
                    key={i}
                    user={item.user.name}
                    stylistId={item.stylist._id}
                    span={item.span}
                    userNumber={item.user.number}
                    services={item.stylist.services}
                    serviceId={item.service._id}
                    price={item.price}
                    manager={item.manager}
                    stylist={item.stylist.name}
                    service={item.service.name}
                    date={item.bookedAt}
                    type={type}
                    bookId={item._id}
                    refresh={refresh}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Books;
