import React from "react";
import PropTypes from "prop-types";
import { shortify } from "../../../../../utils/functions";

export const Item = ({
  name,
  brand,
  onChange,
  storeItems,
  id,
  delHandler,
  className = "",
}) => {
  const body = {
    name,
    brand,
    id,
  };
  const val = storeItems.find(item => item._id === id);
  const price = val.price ?? 0.25;
  return (
    <div className={`item-container ${className}`}>
      <div className="item-info">
        <span>{name}</span>
        &#8226;
        <span>{brand}</span>
      </div>
      <div style={{ display: "flex" }}>
        <input
          type="number"
          placeholder="გრამაჟი"
          onChange={e => onChange(e, id, body)}
          value={val?.spent ? val?.spent : ""}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="price">
            {shortify(val?.spent ? val?.spent * price : 0)}₾
          </span>
          <button onClick={() => delHandler(id)}>წაშლა</button>
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  storeItems: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  delHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  price: PropTypes.number,
};
