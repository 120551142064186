import { LOG_IN, CHANGE_GOLD, LOG_OUT } from "../actions/actiontypes";
import { getLocalStorage, setLocalStorage } from "../utils/functions";

const manager = getLocalStorage("manager");

const initialState = !manager?.isLogged
  ? {
      isLogged: false,
      name: "",
      gold: 0,
      superAdmin: false,
    }
  : manager;

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        ...action.payload,
        superAdmin: action.payload.managerStatus === "normal" ? false : true,
        isLogged: true,
      };
    case CHANGE_GOLD:
      setLocalStorage("manager", { ...state, gold: action.payload });
      return {
        ...state,
        gold: action.payload,
      };
    case LOG_OUT:
      setLocalStorage("manager", "");
      return {
        ...state,
        isLogged: false,
        name: "",
        gold: 0,
        superAdmin: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default mainReducer;
