import React, { useState } from "react";
// import { work } from "../../../../info";
import { AddServices } from "./AddServices";
import { useSelector, useDispatch } from "react-redux";
import { swalert } from "../../../../utils/functions";
import PropTypes from "prop-types";
import { proffesions } from "../../../../info";
import { Item } from "./parts/Item";
import { useDidUpdate } from "../../../../utils/hooks";
import { ADD_REPORTS } from "../../../../actions/actiontypes";
import "./Modal.css";
import { FaButton } from "../../../parts/FaButton";
import { TotalSwitch } from "../../total/TotalSwitch";

export const Modal = ({ name, onClose, stylistId, services, preUser }) => {
  // const services = useSelector(state => state.services);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    price: preUser?.price || services[0]?.service.price,
    service: preUser?.service || services[0]?.service.name,
    serviceid: preUser?.serviceid || services[0]?.service._id,
    client: preUser?.client || "",
    number: preUser?.number || "",
  });
  const store = useSelector(state => state.managerInfo.store);
  const gold = useSelector(state => state.manager.gold);
  const [loading, setLoading] = useState(false);
  const [cash, setCash] = useState(0);
  const [storeItems, setStoreItems] = useState([]);
  const [select, setSelect] = useState(store[0]?.name);
  useDidUpdate(() => {
    setSelect(
      store.filter(item => !JSON.stringify(storeItems).includes(item._id))[0]
        ?.name
    );
  }, [storeItems]);
  const onItemChange = (e, x, y) => {
    const clone = storeItems.map(item => {
      if (x === item._id) {
        return {
          ...item,
          ...y,
          spent: e.target.value,
        };
      }
      return item;
    });
    setStoreItems(clone);
  };
  const appendStore = () => {
    const toAppend = store.find(item => item.name === select);
    setStoreItems([
      ...storeItems,
      {
        ...toAppend,
        spent: 0,
      },
    ]);
  };
  const delHandler = id => {
    const clone = storeItems.filter(item => item._id !== id);
    setStoreItems(clone);
  };
  const handler = async e => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch("/api/v1/record/add", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: state.serviceid,
        stylist: stylistId,
        paymentAmount: Number(state.price),
        paymentMethod: cash,
        clientName: state.client,
        clientNumber: state.number,
        gold: gold,
        storeItems: storeItems.map(item => {
          return {
            ...item,
            item: item.id,
          };
        }),
      }),
    });
    if (res.ok) {
      setLoading(false);
      swalert({
        icon: "success",
        text: "გადახდა წარმატებით განხორციელდა",
      });
      preUser?.deleteHandler && preUser.deleteHandler();
      (async () => {
        const res = await fetch(
          `/api/v1/report/stylist?gold=${gold}&reportType=daily&page=1`
        );
        if (res.ok) {
          const data = await res.json();
          dispatch({
            type: ADD_REPORTS,
            payload: data,
          });
        }
      })();
    } else {
      setLoading(false);
      swalert({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  return (
    <div className="modal-container">
      <img
        src={require("./x.svg")}
        className="modal-x"
        alt="x"
        style={{ filter: "invert(1)" }}
        onClick={onClose}
      />
      <div className="modal-name">
        <h1>{name}</h1>
      </div>
      <div className="modal-grid">
        <div className="modal-services">
          <h4>სერვისი:</h4>
          <select
            value={state.service}
            onChange={e =>
              setState({
                ...state,
                service: e.target.value,
                price: e.target.selectedOptions[0].getAttribute("data-price"),
                serviceid: e.target.selectedOptions[0].getAttribute(
                  "data-serviceid"
                ),
              })
            }
            className="select-w"
          >
            {services.map((item, i) => (
              <option
                key={i}
                value={item.service.name}
                data-price={item.service.price}
                data-serviceid={item.service._id}
              >
                {item.service.name}
              </option>
            ))}
          </select>
        </div>
        <div className="modal-price">
          <h4>მომხმარებელი: </h4>
          <input
            type="text"
            value={state.client}
            onChange={e => setState({ ...state, client: e.target.value })}
          />
        </div>
        <div className="modal-price">
          <h4>ტელ. ნომერი: </h4>
          <input
            type="text"
            value={state.number}
            onChange={e => setState({ ...state, number: e.target.value })}
          />
        </div>
        <div className="modal-price">
          <h4>თანხა: </h4>
          <input
            type="number"
            min="0"
            value={state.price}
            onChange={e => setState({ ...state, price: e.target.value })}
          />
        </div>
      </div>
      {storeItems.length >= 1 ? (
        <div className="store-part">
          {storeItems.map((item, i) => {
            return (
              <Item
                key={i}
                name={item.name}
                brand={item.brand}
                id={item._id}
                storeItems={storeItems}
                onChange={onItemChange}
                delHandler={delHandler}
              />
            );
          })}
        </div>
      ) : null}
      {select && store[0] ? (
        <div className="store-add">
          <select value={select} onChange={e => setSelect(e.target.value)}>
            {store
              .filter(item => !JSON.stringify(storeItems).includes(item._id))
              .map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <button onClick={appendStore}>დამატება</button>
        </div>
      ) : null}
      <TotalSwitch
        status={cash}
        setStatus={setCash}
        textes={["ქეში", "ტერმინალი", "ვაუჩერი"]}
      />
      <FaButton
        icon="faCashRegister"
        className="modal-ok big-font"
        onClick={handler}
        disabled={loading}
      >
        გადახდა
      </FaButton>
    </div>
  );
};

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  stylistId: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  preUser: PropTypes.object,
};

export const AddModal = ({ refresh, update, preInfo, preToSend, id }) => {
  const sv = useSelector(state => state.services);
  const services = sv;
  const gold = useSelector(state => state.manager.gold);
  const [photo, setPhoto] = useState("");
  const [password, setPassword] = useState("");
  const [info, setInfo] = useState(
    update
      ? preInfo
      : {
          name: "",
          profession: "სტილისტი",
          percent: "",
          intern: false,
        }
  );
  const [toSend, setToSend] = useState(
    update
      ? preToSend.map(item => {
          return {
            percentage: item.percentage,
            name: item.service?.name,
            id: item.service?._id,
            profession: item.service?.profession,
            price: item.service?.price,
          };
        })
      : services
          .filter(item => {
            return item.profession === proffesions.indexOf(info.profession);
          })
          .map(item => {
            return { ...item, percentage: 0 };
          })
  );
  const [loading, setLoading] = useState(false);
  useDidUpdate(() => {
    setToSend(
      services
        .filter(item => {
          return item.profession === proffesions.indexOf(info.profession);
        })
        .map(item => {
          return { ...item, percentage: 0 };
        })
    );
  }, [info.profession, services]);
  const formDataMaker = () => {
    const data = new FormData();
    const obj = {
      name: info.name,
      profession: parseInt(proffesions.indexOf(info.profession)),
      services: toSend.map(item => {
        return {
          service: item?.id || item?._id,
          percentage: Number(item?.percentage),
        };
      }),
      salary: 0,
      isFree: true,
      intern: info.intern,
      gold: gold,
      password,
      photo,
    };
    Object.keys(obj).map(item => {
      if (Array.isArray(obj[item])) {
        data.append([item], JSON.stringify(obj[item]));
      } else {
        data.append([item], obj[item]);
      }
    });
    return data;
  };
  const addHandler = async () => {
    setLoading(true);
    const res = await fetch(
      `/api/v1/stylist/${update ? `update/manager/${id}` : "add"}`,
      {
        method: "post",
        body: formDataMaker(),
      }
    );
    if (res.ok) {
      setLoading(false);
      swalert({
        icon: "success",
        title: `კადრი წარმატებით ${update ? "განახლდა" : "დაემატა"}!`,
      });
      refresh();
    } else {
      setLoading(false);
      swalert({
        icon: "error",
        title: "დაფიქსირდა შეცდომა",
      });
    }
  };
  const handler = x => {
    setToSend(x);
  };
  const onChange = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  return (
    <div className="modal-container add-emp">
      <h2 className="new-emp">{update ? "კადრის განახლება" : "ახალი კადრი"}</h2>
      <div className="modal-grid add-grid">
        <div>
          <span>სახელი და გვარი:</span>
          <input
            type="text"
            name="name"
            onChange={onChange}
            value={info.name}
            placeholder="მაგ. ნინი ჭყონია"
          />
        </div>
        <div>
          <span>პროფესია:</span>
          <select
            name="profession"
            value={info.profession}
            onChange={e => setInfo({ ...info, profession: e.target.value })}
            className="prof-select"
          >
            <option>სტილისტი</option>
            <option>მანიკურის სპეციალისტი</option>
            <option>ვიზაჟის სპეციალისტი</option>
            <option>კოსმეტოლოგი</option>
          </select>
        </div>
        <div>
          <span>ფოტო:</span>
          <input type="file" onChange={e => setPhoto(e.target.files[0])} />
        </div>
        <div>
          <span>პაროლი:</span>
          <input type="text" onChange={e => setPassword(e.target.value)} />
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          checked={info.intern}
          name="intern"
          onChange={() => setInfo({ ...info, intern: !info.intern })}
        />
        <span>სტაჟიორი</span>
      </div>
      <AddServices
        services={toSend}
        profession={info.profession}
        handler={handler}
      />
      <FaButton
        className="add-emp-btn big-font"
        icon="faUser"
        onClick={addHandler}
        disabled={loading}
      >
        {update ? "განახლება" : "დამატება"}
      </FaButton>
    </div>
  );
};

AddModal.propTypes = {
  refresh: PropTypes.func.isRequired,
  update: PropTypes.bool.isRequired,
  preInfo: PropTypes.object,
  preToSend: PropTypes.array,
  id: PropTypes.string,
};
