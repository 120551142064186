import React from "react";
import lari from "../assets/lari.svg";
import PropTypes from "prop-types";

export const Lari = ({ white = true }) => {
  return (
    <span className="lari">
      <img src={lari} alt="₾" className={white ? "lari-white" : "lari-black"} />
    </span>
  );
};

Lari.propTypes = {
  white: PropTypes.bool,
};
