import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const Outsider = ({ onOutside, children, className }) => {
  const wrapperRef = useRef(null);
  const [style, setStyles] = useState({
    opacity: 0,
  });
  useEffect(() => {
    const i = e => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setStyles({ opacity: 0 });
        e.preventDefault();
        setTimeout(() => {
          onOutside();
        }, 400);
      }
    };
    setStyles({opacity: 1})
    window.addEventListener("click", i, true);
    return () => {
      window.removeEventListener("click", i, true);
    };
  }, [onOutside]);
  return (
    <div
      ref={wrapperRef}
      style={style}
      className={`outside-wrapper ${className}`}
    >
      {children}
    </div>
  );
};

Outsider.propTypes = {
  onOutside: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
