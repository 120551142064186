import React from "react";
import PropTypes from "prop-types";

export const StylistNav = ({ value, onChange }) => {
  return (
    <div className="stylist-nav">
      <div
        onClick={() => onChange(0)}
        className={value === 0 ? "stylist-nav-active" : ""}
      >
        ნამუშევრები
      </div>
      <div
        onClick={() => onChange(1)}
        className={value === 1 ? "stylist-nav-active" : ""}
      >
        სტატისტიკა
      </div>
      <div
        onClick={() => onChange(2)}
        className={value === 2 ? "stylist-nav-active" : ""}
      >
        ხელფასები
      </div>
    </div>
  );
};

StylistNav.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}
