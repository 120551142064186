import React, { useRef } from "react";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export const Stat = ({ quantity, name, icon }) => {
  const parentRef = useRef(null);
  return (
    <div className="stat" ref={parentRef}>
      <FontAwesomeIcon icon={icons[icon]} color="#ffffff" size="4x" />
      <h2>{quantity}</h2>
      <h3>{name}</h3>
    </div>
  );
};

Stat.propTypes = {
  quantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
