import React, { useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Manager.css";
import { Outsider } from "../../../util_comps/Wrappers";
import { AddManager } from "../addManager/AddManager";

export const Manager = ({ name, salary, id, refresh }) => {
  const MySwal = withReactContent(Swal);
  const [edit, setEdit] = useState(false);
  const delHandler = async () => {
    MySwal.fire({
      title: "დარწმუნებული ხართ?",
      text: "მენეჯერი სამუდამოდ წაიშლება!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "გაუქმება",
      confirmButtonText: "კი",
    }).then(async result => {
      if (result.value) {
        const res = await fetch(`/api/v1/manager/delete/${id}`);
        if (res.ok) {
          MySwal.fire("წაიშალა!", "მენეჯერი წარმატებით წაიშალა.", "success");
          refresh();
        } else {
          MySwal.fire("დაფიქსირდა შეცდომა!", "სცადეთ ხელახლა.", "error");
        }
      }
    });
  };
  return (
    <>
      {edit ? (
        <Outsider onOutside={() => setEdit(false)}>
          <AddManager
            update={true}
            preData={{
              name,
              id,
            }}
            refresh={refresh}
          />
        </Outsider>
      ) : null}
      <div className="manager">
        <div className="manager-info">
          <span className="service-name">{name}</span>
          <span>ხელფასი: {salary}₾</span>
        </div>
        <div className="manager-func">
          {" "}
          <button
            className="primary-button service-btn delete"
            title="მენეჯერის წაშლა"
            onClick={delHandler}
          >
            &nbsp;
          </button>
          <button
            className="primary-button service-btn edit"
            title="მენეჯერის რედაქტირება"
            onClick={() => setEdit(true)}
          >
            &nbsp;
          </button>
        </div>
      </div>
    </>
  );
};

Manager.propTypes = {
  name: PropTypes.string.isRequired,
  salary: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};
