import React, { useState, useEffect } from "react";
import { Navigation } from "../navigation/Navigation";
import { Switch } from "../employees/modal/parts/Switch";
import { useDidUpdate, useTitle } from "../../../utils/hooks";
import { Report } from "./report/Report";
import moment from "moment";
import { useSelector } from "react-redux";
// import { Redirect } from "react-router-dom";
import "./Reports.css";
import { TotalSwitch } from "../total/TotalSwitch";
import { FaButton } from "../../parts/FaButton";

const Reports = () => {
  const gold = useSelector(state => state.manager.gold);
  const { employees, managers } = useSelector(state => state.managerInfo);
  // const [redirect, setRedirect] = useState(false);
  const [weekly, setWeekly] = useState(0);
  const [info, setInfo] = useState({ reports: [], hasMore: true });
  const [emp, setEmp] = useState(true);
  const [page, setPage] = useState({ i: 1, r: false });
  const [loading, setLoading] = useState(false);
  const momentHelper = ["days", "weeks", "months"];
  useDidUpdate(() => {
    setInfo({ reports: [], hasMore: true });
    setPage({ i: 1, r: !page.r });
  }, [weekly, emp, gold]);
  useEffect(() => {
    fetchMore(gold, emp, weekly, page.i);
  }, [page]);
  useTitle("ხელფასები");
  //useDidUpdate(() => {
  //  setPage(1);
  //  setInfo({ reports: [], hasMore: true });
  //  setRedirect(true);
  //}, [gold]);
  const fetchMore = async (gold, emp, weekly, i) => {
    const fetchHelper = ["daily", "weekly", "monthly"];
    setLoading(true);
    const res = await fetch(
      `/api/v1/report/${emp ? `stylist?gold=${gold}&` : "manager?"}reportType=${
        fetchHelper[weekly]
      }&page=${i}`
    );
    if (res.ok) {
      const data = await res.json();
      //if (data[emp ? "reports" : "managers"].length !== 0) {
      setInfo(prevState => ({
        reports:
          prevState.reports.length !== 0
            ? [...prevState.reports, data[emp ? "reports" : "managers"]]
            : [data[emp ? "reports" : "managers"]],
        hasMore: data.hasMore,
      }));
      //} else {
      //  setInfo(prevState => ({
      //    ...prevState,
      //    hasMore: data.hasMore,
      //  }));
      setLoading(false);
    }
  };
  return (
    <div className="reports-page grid-page page">
      <Navigation active="/reports" />
      {/*redirect ? <Redirect to="/reports" /> : null*/}
      <div className="records-page-content">
        <div className="switches">
          <TotalSwitch
            textes={["დღიური", "კვირეული", "თვიური"]}
            status={weekly}
            setStatus={setWeekly}
          />
          <Switch
            status={emp}
            setStatus={setEmp}
            text_1="კადრები"
            text_2="მენეჯერები"
          />
        </div>
        <div>
          {info.reports.map((item, i) => {
            let toAdd = [];
            if (!emp) {
              const ids = item.map(item => item._id);
              toAdd = managers
                .filter(item => !ids.includes(item._id))
                .map(item => {
                  return {
                    ...item,
                    total: 0,
                  };
                });
            } else {
              const ids = item.map(item => item._id);
              toAdd = employees
                .filter(
                  item => !ids.includes(item._id) && Number(item.gold) === gold
                )
                .map(item => {
                  return {
                    ...item,
                    totalEarned: 0,
                    stylist: item.name,
                    totalRecords: 0,
                  };
                });
            }
            return (
              <>
                <h3 style={{ textAlign: "center", color: "#fff" }}>
                  {moment()
                    .subtract(i, momentHelper[weekly])
                    .format(weekly !== 0 ? "MM/YYYY" : "DD/MM/YYYY")}
                </h3>
                <table key={i}>
                  <tbody>
                    <tr className="tr-header">
                      <th>{emp ? "სტილისტი" : "მენეჯერი"}</th>
                      {emp ? <th>სერვისები</th> : null}
                      <th>ხელფასი</th>
                      {emp ? <th>ხარჯი</th> : null}
                    </tr>
                    {[...item, ...toAdd].map((item, i) => {
                      return (
                        <Report
                          key={i}
                          stylist={emp ? item.stylist : item.name}
                          pay={emp ? item.totalEarned : item.total}
                          services={item.totalRecords}
                          storeItemsSpent={
                            emp ? item.storeItemsSpent || 0 : undefined
                          }
                        />
                      );
                    })}
                  </tbody>
                </table>
              </>
            );
          })}
          {!loading && info.hasMore ? (
            <div className="centered">
              <FaButton
                icon="faChevronCircleDown"
                onClick={() =>
                  setPage(prevState => ({
                    r: !prevState.r,
                    i: prevState.i + 1,
                  }))
                }
                className="big-font"
              >
                მეტის ჩატვირთვა
              </FaButton>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Reports;
