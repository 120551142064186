import React from "react";
import { Signup } from "./signup/Signup";
import { Signin } from "./signin/Signin";
import { Link } from "react-router-dom";
import { useTitle } from "../../../utils/hooks";
import PropTypes from "prop-types";
import "../styles/auth.css";

const Auth = ({ signup }) => {
  useTitle(signup ? "რეგისტრაცია" : "შესვლა");
  return (
    <div className="page dark auth-page">
      <div className="top-left-logo">
        <Link to="/">
          <img alt="gold" src={require("../assets/logo.png")} />
        </Link>
      </div>
      <div className="auth-form">
        {signup ? <Signup /> : <Signin />}
        <div className="auth-flat">
          <img
            src={require("../assets/logo.png")}
            alt="gold"
            className="auth-logo"
          />
          <img
            src={require(signup
              ? "../assets/signup.png"
              : "../assets/signin.png")}
            alt="hairdresser"
            className="flat-img"
          />
          <Link to={signup ? "/sign-in" : "/sign-up"}>
            <span className="link">
              {signup ? "უკვე გაქვთ" : "არ გაქვთ"} ანგარიში?
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

Auth.propTypes = {
  signup: PropTypes.bool.isRequired,
};

export default Auth;
