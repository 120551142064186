import React, { useState, useEffect } from "react";
import { NavBar } from "../navbar/NavBar";
import { UserBooking } from "../userDash/UserBooking/UserBooking";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Footer } from "../footer/Footer";
import { useInfiniteScroll } from "react-infinite-scroll-hook";

const StylistVisits = () => {
  const [loading, setLoading] = useState(false);
  const [visits, setVisits] = useState({ hasMore: true, records: [] });
  const [index, setIndex] = useState({ r: false, i: 1 });
  const fetchMore = async () => {
    setLoading(true);
    const res = await fetch(
      `/api/v1/stylist/dashboard/history?page=${index.i}`
    );
    if (res.ok) {
      setVisits(await res.json());
      setIndex({ ...index, i: index.i + 1 });
    } else {
      setLoading(false);
      setVisits({ hasMore: false, records: visits.visits });
    }
  };
  const scrollRef = useInfiniteScroll({
    hasNextPage: visits.hasMore,
    onLoadMore: fetchMore,
    loading,
  });
  // useEffect(() => fetchMore(), []);
  return (
    <div className="user-bookings">
      <NavBar />
      <div className="bookings">
        <div className="user-bookings-header">
          <h2>ისტორია</h2>
        </div>
        <div className="bookings-list responsive-grid" ref={scrollRef}>
          {visits.records.map((item, i) => {
            return (
              <UserBooking
                key={i}
                stylist={item?.stylist?.name || item.stylist || item?.user?.name || item?.client?.name}
                service={item.service.name || item.service}
                gold={item.gold}
                booking={item}
                price={item?.payment?.amount ?? item.price ?? item.payment}
                date={item.bookedAt || item.createdAt}
                id={item._id}
              />
            );
          })}
        </div>
        {visits.records.length === 0 && !loading ? (
          <div className="bookings-info">
            <FontAwesomeIcon icon={icons["faExclamationTriangle"]} size="2x" />
            <h1>ვიზიტები არ არის</h1>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

export default StylistVisits;
