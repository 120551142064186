import React from "react";
import { Lari } from "../reusables/Lari";
import PropTypes from "prop-types";

export const ServiceItem = ({ name, price }) => {
  return (
    <div className="user-service-item">
      <p>{name}</p>
      <p>
        {price}
        <Lari />
      </p>
    </div>
  );
};

ServiceItem.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};
