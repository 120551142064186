import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outsider } from "../../../util_comps/Wrappers";
import { userLogOut } from "../../../../actions/actions";
import { toTop } from "../../utils/utils";
import "../../styles/user.css";

export const User = () => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const { name, type } = useSelector(state => state.user);
  const userMenu =
    type === "user"
      ? [
          {
            href: "/profile",
            name: "პროფილი",
            icon: "faUser",
          },
          {
            href: "/bookings",
            name: "ჯავშნები",
            icon: "faBookMedical",
          },
          {
            href: "/history",
            name: "ისტორია",
            icon: "faHistory",
          },
          {
            onClick: userLogOut,
            name: "გასვლა",
          },
        ]
      : [
          {
            href: "/dashboard/profile",
            name: "პროფილი",
            icon: "faUser",
          },
          {
            href: "/bookings",
            name: "ჯავშნები",
            icon: "faBookMedical",
          },
          {
            href: "/dashboard/visits",
            name: "ისტორია",
            icon: "faHistory",
          },
          {
            onClick: userLogOut,
            name: "გასვლა",
          },
        ];
  // Georgian underlined letter
  const geoDown = "გდევლტუფქღყც";
  // Regex that matches latin alphabet
  const eng = /^[A-Za-z0-9]*$/;
  // If first letter of a name is latin
  const testStatus = eng.test(name.charAt[0]);
  const spanClass = !testStatus ? geoDown.includes(name.charAt[0]) : "";
  return (
    <div className="user-badge" onClick={() => setExpanded(true)}>
      <span className={spanClass ? "geo-down" : ""}>
        {testStatus ? name.charAt(0).toUpperCase() : name.charAt(0)}
      </span>
      {expanded ? (
        <Outsider
          onOutside={() => setExpanded(false)}
          className="user-menu-bar"
        >
          <ul>
            {userMenu.map((item, i) =>
              item.href ? (
                <Link
                  to={item.href}
                  key={i}
                  onClick={()=>toTop()}
                >
                  <li className={item.icon ? "" : "bordered"}>
                    {item.href ? (
                      <FontAwesomeIcon icon={icons[item.icon]} color="#fff" />
                    ) : null}
                    <span>{item.name}</span>
                  </li>
                </Link>
              ) : (
                <li
                  className={item.icon ? "" : "bordered"}
                  onClick={()=>dispatch(item.onClick())}
                >
                  {item.href ? (
                    <FontAwesomeIcon icon={icons[item.icon]} color="#fff" />
                  ) : null}
                  <span>{item.name}</span>
                </li>
              )
            )}
          </ul>
        </Outsider>
      ) : null}
    </div>
  );
};
