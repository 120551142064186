import { USER_LOGOUT } from "./actiontypes";

export const userLogOut = () => async dispatch => {
  const res = await fetch("/api/v1/auth/user/log-out");
  if (res.ok) {
    dispatch({
      type: USER_LOGOUT,
    });
  }
};
