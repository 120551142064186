import { combineReducers } from "redux";
import mainReducer from "./mainReducer";
import managerReducer from "./managerReducer";
import servicesReducer from "./servicesReducer";
import managerInfoReducer from "./managerInfoReducer";
import userReducer from "./userReducer";
export default combineReducers({
  main: mainReducer,
  manager: managerReducer,
  services: servicesReducer,
  user: userReducer,
  managerInfo: managerInfoReducer,
});
