import React, { useState } from "react";
import { useDidUpdate } from "../../../../utils/hooks";
import PropTypes from "prop-types";
import "./Service.css";

export const Service = ({ onChange, name, price, perc = 0 }) => {
  const [service, setService] = useState({
    percent: perc,
  });
  const [backup, setBackup] = useState('');
  //React.useEffect(() => {
  //  setTimeout(() => {
  //    onChange(name, perc);
   // }, 1000);
  //}, []);
  const [per, setPer] = useState(true);
  useDidUpdate(() => {
    onChange(name, 0);
    setService({ ...service, percent: 0 });
  }, [per]);
  useDidUpdate(()=>{
    setService({percent: perc})
  }, [perc])
  const perChange = e => {
    if (per) {
      onChange(name, e.target.value);
      setService({ ...service, percent: e.target.value });
    } else {
      let z = price / 100;
      onChange(name, e.target.value / z);
      setBackup(e.target.value);
      setService({ ...service, percent: e.target.value });
    }
  };
  return (
    <div className="service">
      <div className="service-name-input">{name}</div>
      <input
        type="number"
        onChange={perChange}
        value={per ? service.percent : backup}
        min="0"
      />
      <div
        className="service-switch"
        onClick={() => (price ? setPer(!per) : () => {})}
      >
        <div className={per ? "switch-active" : ""}>%</div>
        <div className={!per ? "switch-active" : ""}>₾</div>
      </div>
    </div>
  );
};

Service.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  perc: PropTypes.number,
};
