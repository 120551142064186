import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const WorkItem = ({ photo, name, stylistPhoto, id }) => {
  return (
    <div className="work-item">
      <Link to={`/stylist/${id}`} onClick={() => window.scrollTo(0, 0)}>
        <div className="work-info">
          <div className="stylist-icon-container">
            <img src={stylistPhoto} alt={name} />
          </div>
          <p>{name}</p>
        </div>
        <div className="work-item-img-container">
          <img src={photo} alt={name} />
        </div>
      </Link>
    </div>
  );
};

WorkItem.propTypes = {
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stylistPhoto: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
