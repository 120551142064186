import React, { useEffect, useState } from "react";
import { NavBar } from "../navbar/NavBar";
import { ServiceItem } from "./ServiceItem";
import { Footer } from "../footer/Footer";
import { useTitle } from "../../../utils/hooks";
import "../styles/servicesPage.css";

const ServicesPage = () => {
  useTitle("სერვისები");
  const [services, setServices] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    (async () => {
      const res = await fetch("/api/v1/service/fetch/all");
      if (res.ok) {
        const data = await res.json();
        setServices(data);
      }
    })();
  }, []);
  return (
    <div className="user-services-page">
      <NavBar />
      <div className="user-bookings-header makeup-bg centered-bg">
        <h2>სერვისები</h2>
      </div>
      <div className="services-search">
        <input
          type="text"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="ძიება"
        />
      </div>
      <div className="user-services-grid">
        {services
          .filter(item => item.name.toUpperCase().includes(search.toUpperCase()))
          .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
          .map((item, i) => {
            return <ServiceItem key={i} {...item} />;
          })}
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
