import React, { useEffect, useState } from "react";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export const ProfileItem = ({ text_1, text_2, color, icon, render }) => {
  const [txt, setTxt] = useState(text_2);
  useEffect(() => {
    setTxt(text_2);
  }, [text_2]);
  return (
    <div className="profile-item" style={{ background: color }}>
      <div className="profile-info">
        <h2>{render ? render(txt) : txt ? txt : "--"}</h2>
        <h4>{text_1}</h4>
        {text_1 === "ბონუს ქულა" ? (
          <a
            href="/help#bonus-points"
            target="_blank"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            წესები
          </a>
        ) : null}
      </div>
      <FontAwesomeIcon icon={icons[icon]} color="#ffffff" size="4x" />
    </div>
  );
};

ProfileItem.propTypes = {
  text_1: PropTypes.any.isRequired,
  text_2: PropTypes.any,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  render: PropTypes.func,
};
