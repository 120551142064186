import React from "react";
import PropTypes from "prop-types";
import './Switch.css';

export const Switch = ({ text_1, text_2, status, setStatus }) => {
  return (
    <div className="cv-radios">
      <div className={`cv-vip-container ${!status && "cv-vipped"}`}>
        <div className="cv-vip-absolute-bg"></div>
        <div
          className={`cv-vip-item ${status && "cv-vipped-item"}`}
          onClick={() => setStatus(true)}
        >
          <p>{text_1}</p>
        </div>
        <div
          className={`cv-vip-item ${!status && "cv-vipped-item"}`}
          onClick={() => setStatus(false)}
        >
          <p>{text_2}</p>
        </div>
      </div>
    </div>
  );
};

Switch.propTypes = {
    text_1: PropTypes.string.isRequired,
    text_2: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    setStatus: PropTypes.func.isRequired
}