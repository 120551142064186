import React from "react";
import PropTypes from "prop-types";

export const Toggle = ({ value, onChange }) => {
  return (
    <div className={`toggle-container ${value ? "toggled" : ""}`} onClick={onChange}>
      <div className="toggle"></div>
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
