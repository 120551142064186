import React, { useState, useEffect } from "react";
import { hours } from "../../../info";
import { useDidUpdate, useFetch } from "../../user/utils/hooks";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { containsObject } from "../../user/utils/utils";
import { getDifference } from "../users/addBook/AddBook";

export const NewTime = ({
  stylistId,
  onChange,
  prevDate,
  onSave,
  onCancel,
  bookingId,
  span,
}) => {
  const [date, setDate] = useState(dayjs(prevDate).format("YYYY-MM-DD"));
  const [index, setIndex] = useState();
  const [time, setTime] = useState(dayjs(prevDate).format("HH:mm"));
  const [endTime, setEndTime] = useState(
    dayjs(prevDate)
      .add(span * 30, "minutes")
      .format("HH:mm")
  );
  const { data: freeTimes } = useFetch(
    `/api/v1/booking/free/${stylistId}?date=${new Date(
      date
    )}&api=move&bookingId=${bookingId}`,
    []
  );
  const hoursAsArray = Object.keys(hours).map(item => {
    return JSON.stringify(hours[item]);
  });
  useEffect(()=>{
    setTime(dayjs(prevDate).format("HH:mm"));
  },[])
  const save = () => {
    onSave({
      bookedAtIndex: index,
      bookedDate: date,
      stylistId: stylistId,
      span: getDifference(time, endTime),
    });
  };
  useEffect(() => {
    let timeObj = JSON.stringify({
      h: new Date(prevDate).getHours(),
      m: new Date(prevDate).getMinutes(),
    });
    setIndex(hoursAsArray.indexOf(timeObj));
  }, [freeTimes]);
  useDidUpdate(() => {
    onChange(index, date);
  }, [index, date]);
  return (
    <div className="new-time-container">
      <div>
        <input
          type="date"
          value={date}
          onChange={e => setDate(e.target.value)}
        />
        <select
          value={time}
          onChange={e => {
            setTime(e.target.value);
            setIndex(e.target.selectedOptions[0].getAttribute("data-index"));
          }}
        >
          {Object.keys(hours).map(item => {
            let obj = hours[item];
            let hour = obj.h.toString().length === 1 ? `0${obj.h}` : obj.h;
            let min = obj.m.toString().length === 1 ? `0${obj.m}` : obj.m;
            return containsObject(obj, freeTimes) ? (
              <option key={item} data-index={item}>{`${hour}:${min}`}</option>
            ) : null;
          })}
        </select>
      </div>
      <div>
        <input
          type="date"
          value={date}
          onChange={e => setDate(e.target.value)}
        />
        <select
          value={endTime}
          onChange={e => {
            setEndTime(e.target.value);
          }}
        >
          {Object.keys(hours).map(item => {
            let obj = hours[item];
            let hour = obj.h.toString().length === 1 ? `0${obj.h}` : obj.h;
            let min = obj.m.toString().length === 1 ? `0${obj.m}` : obj.m;
            return containsObject(obj, freeTimes) ? (
              <option key={item} data-index={item}>{`${hour}:${min}`}</option>
            ) : null;
          })}
        </select>
      </div>
      <div style={{ display: "flex" }}>
        <button onClick={save}>&#10003;</button>
        <button onClick={onCancel}>x</button>
      </div>
    </div>
  );
};

NewTime.propTypes = {
  stylistId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prevDate: PropTypes.any.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  bookingId: PropTypes.string.isRequired,
  span: PropTypes.number.isRequired,
};
