import React from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import "./Transition.css";

export const Fade = ({
  children,
  inprop,
  className = "",
  animation = "my-node",
  td = false,
}) => {
  return (
    <CSSTransition
      in={inprop}
      timeout={300}
      unmountOnExit
      classNames={animation}
    >
      {td ? (
        <tr className={className}>{children}</tr>
      ) : (
        <div className={`${className}`}>{children}</div>
      )}
    </CSSTransition>
  );
};

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  inprop: PropTypes.bool.isRequired,
  className: PropTypes.string,
  animation: PropTypes.string,
};
