import React, { useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { User } from "./user/User";
import { Search } from "../../util_comps/Search";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { useTitle } from "../../../utils/hooks";
import "./Users.css";

const Users = () => {
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ clients: [], hasMore: true });
  const [scrollInfo, setScrollInfo] = useState({
    page: 1,
    loading: false,
  });
  useTitle("მომხმარებლები");
  const searchHandler = () => {
    setSearch(searchValue);
    setData({ clients: [], hasMore: true });
    setScrollInfo({ page: 1, loading: false });
  };
  const fetchMore = async () => {
    setScrollInfo({ ...scrollInfo, loading: true });
    setLoading(true);
    const res = await fetch(
      `/api/v1/user/fetch/all?page=${scrollInfo.page}&keyword=${search}`
    );
    if (res.ok) {
      const info = await res.json();
      setData({
        clients: [...data.clients, ...info.clients],
        hasMore: info.hasMore,
      });
      setScrollInfo({ loading: false, page: scrollInfo.page + 1 });
      setLoading(false);
    }
  };
  const scrollRef = useInfiniteScroll({
    loading: scrollInfo.loading,
    hasNextPage: data.hasMore,
    onLoadMore: fetchMore,
  });
  return (
    <div className="users-page grid-page page">
      <Navigation active="/users" />
      <div className="users-page-content">
        <Search
          loading={loading}
          onChange={e => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="მომხმარებელი"
          onSearch={searchHandler}
        />
        <div ref={scrollRef}>
          {data.clients.map((item, i) => {
            return (
              <User
                key={i}
                id={item._id}
                name={item.name}
                price={item.totalSpent}
                userId={item._id}
                number={item.number}
                services={item.records.length}
                history={item.records}
                isRegistered={item.isRegistered}
                points={item.giftPoints}
              />
            );
          })}
          {data.clients.length === 0 && !loading ? (
            <div className="centered">მომხმარებელი არ მოიძებნა</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Users;
