import React from "react";
import PropTypes from "prop-types";
import { NavBar } from "../navbar/NavBar";
import { StylistInfo } from "./StylistInfo";
import { Gallery } from "./Gallery";
import { Footer } from "../footer/Footer";
import { useFetch, useTitle } from "../utils/hooks";
import { proffesions } from "../../../info";
import "../styles/singleStylist.css";

const sampleBookedHours = ["12:30", "15:00"];

const SignleStylist = ({ id }) => {
  const { data } = useFetch(`/api/v1/stylist/fetch/${id}`, {
    works: [],
    photo: "",
    name: "",
    profession: 0,
    gold: 0
  });
  useTitle(data.name ? data.name : "სტილისტი");
  return (
    <div className="single-stylist-page dark page">
      <NavBar />
      <StylistInfo
        image={
          data.photo
            ? `/api/v1/statics/stylist/photo/${id}`
            : require("../assets/placeholder.png")
        }
        name={data.name}
        id={id}
        gold={data.gold}
        isFree={data.isFree}
        profession={proffesions[data.profession]}
        professionIndex={data.profession}
        booked={sampleBookedHours}
      />
      {data.works.length > 0 ? (
        <Gallery
          images={data.works.map(
            item => `/api/v1/statics/stylist/work/${id}/${item.work}`
          )}
        />
      ) : null}
      <Footer />
    </div>
  );
};

SignleStylist.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SignleStylist;
