import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_GOLD, LOG_OUT } from "../../../actions/actiontypes";
import PropTypes from "prop-types";
import "./Navigation.css";

const Hamburger = ({ isOpened, onClick }) => (
  <div
    className={`hamburger-button ${isOpened ? "hamburger-opened" : ""}`}
    onClick={onClick}
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
);

Hamburger.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const NavItem = ({ icon, href, text, active, onClick = () => {} }) => (
  <div className={`nav-item-wrapper ${active ? "nav-active" : ""}`}>
    <Link to={href ? href : "#"} onClick={onClick}>
      <div className={`nav-item ${active ? "nav-active" : ""}`}>
        <img src={icon} alt={text} />
        <div>{text}</div>
      </div>
    </Link>
  </div>
);

NavItem.propTypes = {
  icon: PropTypes.string.isRequired,
  href: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export const Navigation = ({ active }) => {
  const [opened, setOpened] = useState(false);
  const { gold, name, superAdmin } = useSelector((state) => state.manager);
  const dispatch = useDispatch();
  const changeGold = (x) => {
    dispatch({
      type: CHANGE_GOLD,
      payload: x,
    });
  };
  const adminName = "ჯუნა შიუკაშვილი";
  const logout = async () => {
    const res = await fetch("/api/v1/auth/manager/sign-out");
    if (res.ok) {
      dispatch({
        type: LOG_OUT,
      });
    }
  };
  const items = [
    {
      href: "/employees",
      text: "კადრები",
      icon: require("./icons/employees.svg"),
      condition: superAdmin && name !== adminName,
    },
    {
      href: "/users",
      text: "მომხმარებლები",
      icon: require("./icons/users.svg"),
      condition: false,
    },
    {
      href: "/store",
      text: "საწყობი",
      icon: require("./icons/box.svg"),
      condition: superAdmin && name !== adminName,
    },
    {
      href: "/manager/bookings",
      text: "ჯავშნები",
      icon: require("./icons/bookmark.svg"),
      condition: false,
    },
    {
      href: "/records",
      text: "ჩანაწერები",
      icon: require("./icons/records.svg"),
      condition: false,
    },
    {
      href: "/services",
      text: "სერვისები",
      icon: require("./icons/services.svg"),
      condition: superAdmin && name !== adminName,
    },
  ];
  return (
    <>
      <Hamburger isOpened={opened} onClick={() => setOpened(!opened)} />
      <div className="nav-wrapper">
        <div className={`navigation-container ${opened ? "nav-opened" : ""}`}>
          <div className="navigation-logo">
            <h2>{name}</h2>
          </div>
          <div className="navigation-items">
            {items.map((item, i) =>
              item.condition ? null : (
                <NavItem
                  key={i}
                  active={item.href === active}
                  href={item.href}
                  text={item.text}
                  icon={item.icon}
                />
              )
            )}
            {superAdmin && name === adminName ? (
              <>
                <NavItem
                  active={"/managers" === active}
                  href={"/managers"}
                  text={"მენეჯერები"}
                  icon={require("./icons/managers.svg")}
                />
                <NavItem
                  active={"/reports" === active}
                  href={"/reports"}
                  text="ხელფასები"
                  icon={require("./icons/sheets.svg")}
                />
                <NavItem
                  active={"/message" === active}
                  href={"/message"}
                  text="მესიჯი"
                  icon={require("./icons/msg.svg")}
                />
              </>
            ) : null}
            {superAdmin && name !== adminName ? null : (
              <NavItem
                active={"/total" === active}
                href={"/total"}
                text="აღრიცხვა"
                icon={require("./icons/total.svg")}
              />
            )}
            {!superAdmin ? null : (
              <NavItem
                active={"/widgets" === active}
                href={"/widgets"}
                text="ვიჯეტები"
                icon={require("./icons/widgets.svg")}
              />
            )}
            <NavItem
              icon={require("./icons/pass.svg")}
              text="პაროლის შეცვლა"
              active={active === "/change-password"}
              href="/change-password"
            />
            <NavItem
              icon={require("./icons/box.svg")}
              text="გაყიდვები"
              active={active === "/sales"}
              href="/sales"
            />
            <NavItem
              icon={require("./icons/logout.svg")}
              text="გასვლა"
              active={false}
              href={false}
              onClick={logout}
            />
            {superAdmin ? (
              <div className="gold-switch gold-nav-switch">
                <div
                  className={`gold-switch-item ${
                    gold === 0 ? "gold-chosen" : ""
                  }`}
                  onClick={() => changeGold(0)}
                >
                  <span>I</span>
                </div>
                <div
                  className={`gold-switch-item ${
                    gold === 1 ? "gold-chosen" : ""
                  }`}
                  id="1"
                  onClick={() => changeGold(1)}
                >
                  <span>II</span>
                </div>
                <div
                  className={`gold-switch-item ${
                    gold === 2 ? "gold-chosen" : ""
                  }`}
                  id="2"
                  onClick={() => changeGold(2)}
                >
                  <span>III</span>
                </div>
              </div>
            ) : null}
          </div>

          {/*[0, 1, 2].map(item => (
                <div
                  key={item}
                  className={`nav-item ${
                    gold === item ? "gold-active" : ""
                  } gold-nav`}
                  onClick={() => changeGold(item)}
                >
                  <img src={require(`./icons/${item}.svg`)} alt="gold" />
                  <span>გოლდი {item + 1}</span>
                </div>
              )) */}
        </div>
      </div>
    </>
  );
};

Navigation.propTypes = {
  active: PropTypes.string.isRequired,
};
