import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaButton } from "../../parts/FaButton";
import { Navigation } from "../navigation/Navigation";
import "./Sales.css";

export const Sales = () => {
  const { gold, superAdmin } = useSelector((state) => state.manager);
  const [records, setRecords] = useState([]);
  const [index, setIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [deletedIndexes, setDeletedIndexes] = useState([]);

  useEffect(() => {
    const getRecords = async () => {
      setRecords([]);
      const res = await fetch(
        `/api/v1/store/record/fetch/all?gold=${gold}&page=${index}`
      );
      const data = await res.json();
      setRecords((prevState) => [...prevState, ...data.storeItemRecords]);
      setHasMore(data.hasMore);
    };
    getRecords();
  }, [gold, index]);

  const deleteStoreRecord = async (id, idx) => {
    const res = await fetch(`/api/v1/store/record/delete/${id}`);
    if (res.ok) {
      setDeletedIndexes([...deletedIndexes, idx]);
    }
  };

  return (
    <div className="grid-page">
      <Navigation active="/sales" />
      <div>
        {records.map((val, idx) => {
          if (deletedIndexes.includes(idx)) {
            return null;
          }

          return (
            <div key={idx} className="sale-item">
              <span>ნივთი: {val.storeItem.name}</span>
              <span>რაოდენობა: {val.amount}</span>
              <span>
                დრო: {dayjs(val.createdAt).format("DD/MM/YYYY hh:mm")}
              </span>
              <span>ჯამი: {val.amount * val.storeItem.price}₾</span>
              <span>მენეჯერი: {val.manager}</span>
              {superAdmin ? (
                <FaButton onClick={() => deleteStoreRecord(val._id, idx)}>
                  წაშლა
                </FaButton>
              ) : null}
            </div>
          );
        })}
        <div style={{ textAlign: "center" }}>
          {hasMore ? (
            <FaButton onClick={() => setIndex((prevState) => prevState + 1)}>
              მეტის ჩატვირთვა
            </FaButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};
