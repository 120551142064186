import React from "react";
import { NavBar } from "../navbar/NavBar";
import { useHistory } from "react-router-dom";
import { UserStylist } from "./UserStylist";
import { Footer } from "../footer/Footer";
import { useFetch } from "../utils/hooks";
import { UserSwitch } from "./UserSwitch";
import { useTitle } from "../../../utils/hooks";
import { ProfSwitch } from "./ProfSwitch";
import qs from "querystring";
import "../styles/stylists.css";

const Stylists = () => {
  const history = useHistory();
  useTitle("სპეციალისტები");
  const headerHelper = [
    {
      name: "სტილისტები",
      className: "stylists-bg",
    },
    {
      name: "მანიკურის სპეციალისტები",
      className: "nails-bg",
    },
    {
      name: "ვიზაჟის სპეციალისტები",
      className: "makeup-bg",
    },
    {
      name: "კოსმეტოლოგები",
      className: "cosmetolog-bg",
    },
  ];
  // const [gold, setGold] = useState(0);
  // const [prof, setProf] = useState(0);
  const search = {
    gold: 0,
    profession: 0,
    ...qs.parse(window.location.search.substring(1)),
  };
  const { data } = useFetch(
    `/api/v1/stylist/fetch/all-allowed?gold=${search.gold || 0}`,
    []
  );
  const profChange = num => {
    history.push(`/stylists?${qs.stringify({ ...search, profession: num })}`);
  };
  const goldChange = num => {
    history.push(`/stylists?${qs.stringify({ ...search, gold: num })}`);
  };
  const filterer = profession => {
    return data.filter(item => item.profession === profession);
  };
  return (
    <div className="stylists-page page">
      <NavBar />
      <div
        className={`user-bookings-header ${
          headerHelper[search.profession || 0].className
        } centered-bg`}
      >
        <h2>{headerHelper[search.profession || 0].name}</h2>
        <UserSwitch value={Number(search.gold || 0)} onChange={goldChange} />
        <ProfSwitch value={Number(search.profession || 0)} onChange={profChange} />
      </div>
      <div className="stylists-grid responsive-grid">
        {filterer(Number(search.profession || 0)).map((item, i) => {
          return (
            <UserStylist
              key={i}
              name={item.name}
              id={item._id}
              profession={item.profession}
              isFree={item.isFree}
              src={
                item.photo
                  ? `/api/v1/statics/stylist/photo/${item._id}`
                  : require("../assets/placeholder.png")
              }
              gold={Number(search.gold || 0)}
            />
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default Stylists;
