import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import * as icons from "@fortawesome/free-solid-svg-icons";

export const Input = ({ icon, className = "", invalid = false, ...rest }) => {
  // To change styles depending on focus status
  const [focused, setFocused] = useState(false);
  return (
    <div
      className={`reusable-input ${
        focused ? "input-focused" : ""
      } ${className} ${invalid ? "invalid-input" : ""}`}
    >
      <FontAwesomeIcon
        icon={icons[icon] ? icons[icon] : icons.faCoffee}
        color={invalid ? "red" : "#ffffff"}
      />
      <input
        {...rest}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
        }}
      />
    </div>
  );
};

Input.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  invalid: PropTypes.bool,
};
