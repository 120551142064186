import {
  ADD_EMPLOYEES,
  ADD_MANAGERS,
  ADD_STORE,
  ADD_REPORTS,
} from "../actions/actiontypes";

const initialState = {
  managers: [],
  employees: [],
  store: [],
  reports: [],
};

const managerInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case ADD_MANAGERS:
      return state.managers.length === 0
        ? {
            ...state,
            managers: action.payload,
          }
        : { ...state };
    case ADD_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case ADD_REPORTS:
      return {
        ...state,
        reports: action.payload.reports,
      };
    default:
      return {
        ...state,
      };
  }
};

export default managerInfoReducer;
