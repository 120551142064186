import { useEffect } from "react";
import sound from "../components/user/assets/notification.mp3";
import Swal from "sweetalert2";

export const useNotification = (gold, isLogged) => {
  const notificationSound = new Audio(sound);
  useEffect(() => {
    const func = async () => {
      const res = await fetch(
        `/api/v1/booking/fetch/manager?gold=${gold}&approved=false`
      );
      if (res.ok) {
        const data = await res.json();
        if (data.length !== 0) {
          const prevTitle = document.title;
          document.title = `ჯავშნები (${data.length})`;
          notificationSound.play();
          Swal.fire({
            icon: "info",
            title: "შეტყობინება",
            text: `დაგროვილია ${data.length} დაუდასტურებელი ჯავშანი`,
          }).then(() => (document.title = prevTitle));
        }
      }
    };
    if (isLogged && window.location.pathname !== "/manager/bookings") func();
    const interval = setInterval(
      isLogged && window.location.pathname !== "/manager/bookings"
        ? () => func()
        : () => {},
      300000
    );
    return () => clearInterval(interval);
  }, [gold, isLogged]);
};
