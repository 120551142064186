import React, { useState } from "react";
import PropTypes from "prop-types";
import { Fade } from "../../../transitions/Fade";
import { AddBook } from "../addBook/AddBook";
import moment from "moment";
import "./User.css";
import { FaButton } from "../../../parts/FaButton";

export const User = ({
  id,
  name,
  price,
  services,
  history,
  number,
  userId,
  isRegistered,
  points
}) => {
  const [fade, setFade] = useState(false);
  const [modal, setModal] = useState(false);
  const [giftPoints, setGiftPoints] = useState(points ?? 0);

  const nullify = async () => {
    const res = await fetch(`/api/v1/user/gift-points/nullify/${id}`);
    if (res.ok) {
      setGiftPoints(0);
    }
  };
  return (
    <>
      {modal ? (
        <AddBook
          userId={userId}
          name={name}
          onClose={() => setModal(false)}
          number={number}
        />
      ) : null}
      <div className="user-container">
        <div className="user-info">
          <span style={{ textAlign: "center" }}>
            <a href={`/manager/profile/${userId}?name=${name}`} target="_blank">
              {name}
            </a>
          </span>
          <span>ნომერი: {number}</span>
          <span>დახარჯული: {price}₾</span>
          <span>სერვისები: {services}</span>
          <span>{isRegistered ? "goldi.ge" : "ფილიალი"}</span>
          <div className="user-props">
            <FaButton icon="faBookmark" onClick={() => setModal(true)}>
              დაჯავშნა
            </FaButton>
            <img
              src={require("./arrow.svg")}
              alt="arrow"
              className={`user-arrow ${fade ? "user-arrow-down" : ""}`}
              onClick={() => setFade(!fade)}
            />
          </div>
        </div>
        <Fade inprop={fade} className="user-history">
          <div className="fake-margin">
            <div className="fake-border"></div>
            <span>ისტორია</span>
          </div>
          {history.map((item, i) => {
            return (
              <div key={i} className="user-history-item">
                <span>{item.service.name}</span>
                <span>ფასი: {item.payment.amount}₾</span>
                <span>
                  თარიღი: {moment(item.createdAt).format("DD/MM/YY HH:mm")}
                </span>
                <span>გოლდი {item.gold + 1}</span>
              </div>
            );
          })}
          <div style={{marginTop: '1em'}}>
            <span>ბონუს ქულები: {giftPoints}</span>
            {giftPoints ? <FaButton onClick={nullify}>განულება</FaButton> : null}
          </div>
        </Fade>
      </div>
    </>
  );
};

User.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  services: PropTypes.number.isRequired,
  history: PropTypes.array.isRequired,
  number: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isRegistered: PropTypes.bool.isRequired,
};
