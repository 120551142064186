import React from "react";
import PropTypes from "prop-types";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";

export const FaButton = ({ children, icon, className = "", ...rest }) => {
  return (
    <button className={`fa-button ${className}`} {...rest}>
      <FontAwesomeIcon icon={icons[icon]} color="#ffffff" />
      <span>{children}</span>
    </button>
  );
};

FaButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};
