import { useEffect, useRef, useState } from "react";

export const useDidUpdate = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useFetch = (api, defaultData = {}, options = {}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const clear = () => setData(defaultData);
  const refresh = () => setRefetch(!refetch);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetch(api, options);
        if (res.ok) {
          const i = await res.json();
          setData(i);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [refetch]);
  return { loading, data, error, refresh, clear };
};

export const useTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};
