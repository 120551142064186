import React from "react";
import PropTypes from "prop-types";
import { proffesions } from "../../../info";
import { CustomLink } from "../reusables/CustomLink";

export const UserStylist = ({ src, name, profession, gold, id, isFree }) => {
  // Helper to convert number to romanian
  const numHelper = ["I", "II", "III"];
  return (
    <div className="user-stylist-container">
      <CustomLink to={`/stylist/${id}`}>
        <div className="user-stylist-image-container">
          <img src={src} alt={name} />
        </div>
        <div className="user-stylist-info">
          <p className="user-stylist-name">{name}</p>
          <div className="border golden" />
          <div className="stylist-flex">
            <div>
              <p>{proffesions[profession]}</p>
              <p>გოლდი {numHelper[gold]}</p>
            </div>
            <div>
              <p>&nbsp;</p>
              <p className={isFree ? "green" : "red"}>
                {isFree ? "თავისუფალია" : "დაკავებულია"}
              </p>
            </div>
          </div>
        </div>
      </CustomLink>
    </div>
  );
};

UserStylist.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profession: PropTypes.number.isRequired,
  gold: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isFree: PropTypes.bool.isRequired,
};
