import React, {useState, useEffect} from "react";
import { ProfileItem } from "../userDash/Profile/ProfileItem";
import { useFetch } from "../utils/hooks";

const preStats = [
  {
    name: "გამომუშავებული",
    text: 2,
    color: "#522f19",
    icon: "faMoneyBill",
    navigator: "totalEarned",
  },
  {
    name: "გამოცდილება",
    text: "243",
    color: "#9d764b",
    icon: "faCalendarAlt",
    navigator: "xp",
  },
  {
    name: "სერვისები",
    text: "29",
    color: "#92959e",
    icon: "faConciergeBell",
    navigator: "totalRecords",
  },
  {
    name: "სტატუსი",
    text: "ახალი",
    color: "#f99969",
    icon: "faMedal",
    navigator: "status",
  },
  {
    name: "ერთგული კლიენტი",
    text: null,
    color: "#bd67a9",
    icon: "faUserTag",
    navigator: "favClient",
  },
  {
    name: "აქტიური პროცედურა",
    text: null,
    color: "#be8c10",
    icon: "faFan",
    navigator: "activeService",
  },
];

export const StylistStats = () => {
  const [stats, setStats] = useState([]);
  const {data} = useFetch('/api/v1/stylist/dashboard/stats', {});
  useEffect(()=>{
    Object.keys(data).map((item, i) => {
      preStats[i].text = data[item];
    });
    setStats(preStats);
  },[data])
  return (
    <div className="profile-items dark responsive-grid">
      {stats.map((item, i) => (
        <ProfileItem
          key={i}
          text_1={item.name}
          color={item.color}
          text_2={item.text}
          icon={item.icon}
          redner={item.render}
        />
      ))}
    </div>
  );
};
