import React, { useState } from "react";
import { post } from "../utils/utils";
import { useHistory } from "react-router-dom";
import { useTitle } from "../../../utils/hooks";
import { NavBar } from "../navbar/NavBar";
import { Input } from "../reusables/Input";
import { UserLoadButton } from "../reusables/UserLoadButton";
import { UserModal } from "../reusables/UserModal";
import Swal from "sweetalert2";
import "../styles/forgotpass.css";
import { pop } from "../../../utils/functions";

const Forgot = () => {
  useTitle("პაროლის აღდგენა");
  const history = useHistory();
  const [inputs, setInputs] = useState({
    number: "",
    password: "",
  });
  const onChange = e => {
    e.persist();
    setInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const [sent, setSent] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const sendNumber = async e => {
    e.preventDefault();
    setLoading(true);
    const res = await post("https://goldi.ge/api/v1/auth/user/forgot", {
      number: inputs.number,
    });
    if (res.ok) {
      setLoading(false);
      setSent(true);
    } else {
      pop({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  const sendCode = async e => {
    e.preventDefault();
    setLoading(true);
    const res = await post("https://goldi.ge/api/v1/auth/user/recover/check", {
      code: Number(code),
      number: inputs.number,
    });
    if (res.ok) {
      setVerified(true);
      setLoading(false);
      setSent(false);
    } else {
      pop({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  const sendPassword = async e => {
    e.preventDefault();
    const res = await post("https://goldi.ge/api/v1/auth/user/recover/verify", {
      number: inputs.number,
      code: Number(code),
      password: inputs.password,
    });
    if (res.ok) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        texT: "პაროლი წარმატებით შეიცვალა",
      }).then(() => {
        history.push("/sign-in");
      });
    } else {
      pop({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  return (
    <div className="forgot-pass-page dark page">
      <NavBar />
      {sent ? (
        <UserModal onClose={() => setSent(false)}>
          <h2 style={{ textAlign: "center", color: "#fff" }}>
            შეიყვანეთ მობილურზე მოსული ოთხნიშნა კოდი
          </h2>
          <Input
            type="text"
            placeholder="კოდი"
            icon="faComment"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          <UserLoadButton
            text="OK"
            loading={loading}
            style={{ marginTop: "1.5rem" }}
            onClick={sendCode}
          />
        </UserModal>
      ) : null}
      <div className="forgot-pass-content">
        <form className="forgot-pass-form">
          {verified ? (
            <>
              <h3>მიუთითეთ ახალი პაროლი</h3>
              <Input
                icon="faLock"
                placeholder="პაროლი"
                name="password"
                onChange={onChange}
                value={inputs.password}
              />
              <button className="golden-button" onClick={sendPassword}>
                შენახვა
              </button>
            </>
          ) : (
            <>
              <h3>მიუთითეთ თქვენი მობილურის ნომერი</h3>
              <Input
                icon="faPhone"
                placeholder="ტელეფონი"
                name="number"
                onChange={onChange}
                value={inputs.number}
              />
              <button className="golden-button" onClick={sendNumber}>
                გაგზავნა
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Forgot;
