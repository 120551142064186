import React from "react";
import { UserService } from "./UserService";
import { useSelector } from "react-redux";
import "../../styles/userServices.css";

const ids = [
  "5f3e7a348192577997f33e98",
  "5f3fde368192577997f33ec5",
  "5f5bb5ecfaabb62afcdaa9fc",
  "5f522082b902e72271dffef5",
];

const photos = [
  require("../../assets/makeup.jpg"),
  require("../../assets/shilak.jpg"),
  require("../../assets/ombre.jpg"),
  require("../../assets/laser.jpg"),
];

export const UserServices = () => {
  const services = useSelector(state => state.services).filter(item =>
    ids.includes(item._id)
  );
  return (
    <div className="user-services-wrapper">
      <div className="popular-stylist-header-new">
        <h2>მოთხოვნადი სერვისები</h2>
        <p>
          ჩვენთან ყველა სერვისი ტარდება პროფესიონალების<br/> მიერ ყველანაირი ნორმების
          დაცვით
        </p>
      </div>
      <div className="user-services-container">
        {services.map((item, i) => {
          return (
            <UserService
              key={i}
              name={item.name}
              price={item.price}
              src={photos[i]}
            />
          );
        })}
      </div>
    </div>
  );
};
