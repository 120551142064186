import React from "react";
import { useTitle } from "../../../utils/hooks";
import { Footer } from "../footer/Footer";
import { NavBar } from "../navbar/NavBar";
import { Intro } from "./intro/Intro";
import { LastWork } from "./lastWork/LastWork";
import { Stats } from "./stats/Stats";
import { UserServices } from "./userServices/UserServices";
import { WhyUs } from "./whyUs/WhyUs";
import { Pricing } from './pricing/Pricing';
import { Widgets } from "./widgets/Widgets";
import { Banner } from "./banner/Banner";

const Landing = () => {
  useTitle("მთავარი");
  return (
    <div className="page dark landing-page">
      <NavBar />
      <Intro />
      <Banner/>
      <Widgets />
      <UserServices />
      <Stats />
      <LastWork />
      <Footer />
    </div>
  );
};

export default Landing;
