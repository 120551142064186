import React, { useRef } from "react";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export const UploadPhoto = ({ value, onChange }) => {
  const inputRef = useRef(null);
  return (
    <div className="upload-photo" onClick={() => inputRef.current.click()}>
      <input
        type="file"
        value={value}
        onChange={onChange}
        style={{ display: "none" }}
        ref={inputRef}
      />
      <div className="upload-photo-content">
        <FontAwesomeIcon icon={faCamera} size="2x"/>
        <h3>ფოტოს დამატება</h3>
      </div>
    </div>
  );
};

UploadPhoto.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
