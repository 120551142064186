import React, { useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { LoadButton } from "../../util_comps/LoadButton";
import { swalert } from "../../../utils/functions";
import "./PassChange.css";

const PassChange = () => {
  const [info, setInfo] = useState({
    newPassRep: "",
    newPass: "",
  });
  const [loading, setLoading] = useState(false);
  const onChange = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const changeHandler = async e => {
    e.preventDefault();
    setLoading(true);
    if (info.newPass === info.newPassRep) {
      const res = await fetch(`/api/v1/manager/update/password`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: info.newPass,
        }),
      });
      if (res.ok) {
        setLoading(false);
        swalert({
          icon: "success",
          text: "პაროლი წარმატებით განახლდა",
        });
        setInfo({
          newPass: '',
          newPassRep: ''
        })
      } else {
        setLoading(false);
        swalert({
          icon: "error",
          text: "დაფიქსირდა შეცდომა",
        });
      }
    } else {
      swalert({
        icon: "error",
        text: "პაროლები არ ემთხვევა",
      });
      setInfo({
        newPass: "",
        newPassRep: "",
      });
    }
  };
  return (
    <div className="pass-change-page grid-page page">
      <Navigation active="/change-password" />
      <div className="pass-change-content">
        <div className="pass-change-form-container">
          <form className="pass-change-form">
            <input
              type="password"
              value={info.newPass}
              name="newPass"
              onChange={onChange}
              placeholder="ახალი პაროლი"
            />
            <input
              type="password"
              value={info.newPassRep}
              name="newPassRep"
              onChange={onChange}
              placeholder="გაიმეორეთ პაროლი"
            />
            <LoadButton
              loading={loading}
              onClick={changeHandler}
              text="შეცვლა"
              cn="per20"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PassChange;
