import React, { useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { useFetch, useTitle } from "../../../utils/hooks";
import { Service } from "./Service";
import { Loading, Error } from "../../parts/PageStatuses";
import { Outsider } from "../../util_comps/Wrappers";
import { AddService } from "./AddService";
import "./Services.css";
import { FaButton } from "../../parts/FaButton";

const Services = () => {
  const { data, loading, error, refresh } = useFetch(
    "/api/v1/service/fetch/all",
    []
  );
  const [service, setService] = useState(false);
  useTitle("სერვისები");
  return (
    <div className="services-page page grid-page">
      <Navigation active="/services" />
      <div className="services-page-content">
        {!loading && !error ? (
          <div className="centered">
            <FaButton
              icon="faPlusCircle"
              onClick={() => setService(true)}
              className="big-font"
              style={{margin: 10}}
            >
              სერვისის დამატება
            </FaButton>
          </div>
        ) : null}
        {data.map((item, i) => {
          return (
            <Service
              key={i}
              name={item.name}
              price={item.price}
              id={item._id}
              profession={item.profession}
              refresh={refresh}
            />
          );
        })}
        {loading ? <Loading /> : null}
        {error ? <Error /> : null}
      </div>
      {service ? (
        <Outsider onOutside={() => setService(false)}>
          <AddService update={false} refresh={refresh} />
        </Outsider>
      ) : null}
    </div>
  );
};

export default Services;
