import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const swalert = props => {
  MySwal.fire({ ...props });
};

export const pop = (options, callback_1 = () => {}, callback_2 = () => {}) => {
  MySwal.fire(options).then(result => {
    return result.isConfirmed ? callback_1() : callback_2();
  });
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    return localStorage.getItem(key);
  }
};

export const removeLocalStorage = key => {
  localStorage.removeItem(key);
};

export const shortify = num => {
  if (typeof num === "undefined" || num === null) return 0;
  let n = Number(num);
  let toReturn = Number.isInteger(n) ? n : n.toFixed(1);
  toReturn = toReturn.toString().endsWith(".0")
    ? toReturn.substring(0, toReturn.length - 2)
    : toReturn;
  return toReturn;
};

export const isBadTime = () => {
  return new Date().getHours() <= 8 && new Date().getHours() >= 0;
};

export const safeNum = x => {
  return isNaN(Number(x)) ? x : Number(x);
};

export const isMoreThanOneWeek = (type, num, superAdmin) => {
  if (superAdmin) {
    return false;
  }
  switch (type) {
    case "weeks":
      return true;
    case "days":
      return num >= 7;
    case "months":
      return true;
    default:
      return true;
  }
};
