import React from "react";
import PropTypes from "prop-types";

export const Search = ({ value, onChange, placeholder, onSearch, loading }) => {
  const keyDown = e => {
    if(e.keyCode===13){
      onSearch();
    }
  }
  return (
    <div className="records-search-container">
      <div className="records-search">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={keyDown}
        />
        <button className="search-btn primary-button" onClick={onSearch} disabled={loading}>
          &nbsp;
        </button>
      </div>
    </div>
  );
};

Search.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
