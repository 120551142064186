import React, { useState } from 'react';
import { FaButton } from '../../parts/FaButton';
import { Input } from '../../user/reusables/Input';

export const AddWidgetModal = ({refresher, closeHandler}) => {
    const [data, setData] = useState({
        text: '',
        file: ''
    })

    const inputChangeHandler = ev => {
        const target = ev.target;
        setData(prevState => ({...prevState, text: target.value}))
    }

    const onUpload = ev => {
        const target = ev.target;
        setData(prevState => ({...prevState, file: target.files[0]}))
    }

    const addHandler = async () => {
        const formData = new FormData();
        formData.append('text', data.text);
        formData.append('image', data.file)
        const res = await fetch('/api/v1/widget/add', {
            method: 'post',
            body: formData
        })
        if(res.ok){
            refresher();
            closeHandler()
        }
    }

    return (
        <div className="modal-container add-emp">
            <Input placeholder="ტექსტი" icon="Text" value={data.text} onChange={inputChangeHandler}/>
            <input type="file" placeholder="აირჩიეთ ფაილი" onChange={onUpload} style={{margin: '2em 0'}}/>
            <FaButton onClick={addHandler}>დამატება</FaButton>
        </div>
    )
}