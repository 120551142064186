import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTitle } from "../../../../utils/hooks";
import { Footer } from "../../footer/Footer";
import { NavBar } from "../../navbar/NavBar";
import { Lari } from "../../reusables/Lari";
import { ProfileItem } from "./ProfileItem";
import qs from "querystring";
import PropTypes from "prop-types";
import "../../styles/profile.css";

const preStats = [
  {
    name: "დახარჯული",
    render: x => (
      <>
        {x}
        <Lari />
      </>
    ),
    text: 2,
    color: "#522f19",
    icon: "faMoneyBill",
    navigator: "totalSpent",
  },
  {
    name: "დღეები",
    text: "243",
    color: "#9d764b",
    icon: "faCalendarAlt",
    navigator: "totalDays",
  },
  {
    name: "სერვისები",
    text: "29",
    color: "#92959e",
    icon: "faConciergeBell",
    navigator: "totalRecords",
  },
  {
    name: "სტატუსი",
    text: "ახალი",
    color: "#f99969",
    icon: "faMedal",
    navigator: "status",
  },
  {
    name: "ფავორიტი სტილისტი",
    text: null,
    color: "rgb(132 165 66)",
    icon: "faUserTag",
    navigator: "favStylist",
  },
  {
    name: "ბოლო ვიზიტი",
    text: "",
    color: "#b63c0b",
    icon: "faCut",
    navigator: "lastVisit",
  },
  {
    name: "საყვარელი პროცედურა",
    text: null,
    color: "#be8c10",
    icon: "faFan",
    navigator: "favService",
  },
  {
    name: "ბონუს ქულა",
    text: null,
    color: "#efba76",
    icon: "faGift",
    navigator: "giftPoints",
  },
];

const Profile = ({ id }) => {
  const [stats, setStats] = useState([]);
  useTitle("პროფილი");
  const { name } = useSelector(state => state.user);

  useEffect(() => {
    (async () => {
      const res = await fetch(`/api/v1/user/stats/${id ? `manager?userId=${id}` : "user"}`);
      if (res.ok) {
        const data = await res.json();
        Object.keys(data).map((item, i) => {
          preStats[i].text = data[item];
        });
        setStats(preStats);
      }
    })();
  }, []);
  return (
    <div className="profile-page page dark">
      <NavBar />
      <div className="profile-header">
        <h1>{name || qs.parse(window.location.search.substring(1)).name}</h1>
      </div>
      <div className="profile-items responsive-grid">
        {stats.map((item, i) => (
          <ProfileItem
            key={i}
            text_1={item.name}
            color={item.color}
            text_2={item.text}
            icon={item.icon}
            redner={item.render}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

Profile.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Profile;
