import React from "react";
import PropTypes from "prop-types";

export const UserSwitch = ({ value, onChange }) => {
  const numHelper = ["I", "II", "III"];
  return (
    <div className="user-switch-container">
      {[0, 1, 2].map(item => {
        return (
          <div
            key={item}
            className={`user-switch-item ${
              value === item ? "user-switch-active" : ""
            }`}
            onClick={() => onChange(item)}
          >
            გოლდი {numHelper[item]}
          </div>
        );
      })}
    </div>
  );
};

UserSwitch.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
