import React, { useEffect, useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { StoreItem } from "./StoreItem/StoreItem";
import { Loading, Error } from "../../parts/PageStatuses";
import { StoreModal } from "./StoreModal/StoreModal";
import { Outsider } from "../../util_comps/Wrappers";
import { useDidUpdate, useTitle } from "../../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { ADD_STORE } from "../../../actions/actiontypes";
import { safeNum } from "../../../utils/functions";
import "./Store.css";
import { FaButton } from "../../parts/FaButton";

const Store = () => {
  const dispatch = useDispatch();
  const { gold, superAdmin } = useSelector((state) => state.manager);
  const [data, setData] = useState({ storeItems: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [add, setAdd] = useState(false);
  useTitle("საწყობი");
  const refetch = async () => {
    const res = await fetch(`/api/v1/store/fetch/all?gold=${gold}`);
    if (res.ok) {
      let info = await res.json();
      setData({
        storeItems: info.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        }),
      });
      setLoading(false);
      dispatch({
        type: ADD_STORE,
        payload: info,
      });
    } else {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(()=>{
    refetch();
  },[])

  useDidUpdate(() => {
    window.location.reload();
  }, [gold]);

  return (
    <div className="grid-page store-page page">
      <Navigation active="/store" />
      <div className="records-page-content">
        {!error && superAdmin ? (
          <div className="centered">
            <FaButton
              icon="faPlusCircle"
              onClick={() => setAdd(true)}
              className="big-font"
            >
              პროდუქტის დამატება
            </FaButton>
          </div>
        ) : null}
        <table>
          <tbody>
            <tr className="tr-header">
              <th>ნომერი</th>
              <th>რაოდენობა</th>
              <th>ფასი</th>
              <th>ფირმა</th>
            </tr>
            {data.storeItems.map((item, i) => {
              return (
                <StoreItem
                  key={i}
                  id={item._id}
                  amount={item.amount}
                  name={item.name}
                  brand={item.brand}
                  gold={gold}
                  price={item.price}
                />
              );
            })}
          </tbody>
        </table>
        {loading ? <Loading /> : null}
        {error ? <Error /> : null}
        {add ? (
          <Outsider onOutside={() => setAdd(false)}>
            <StoreModal append={refetch} />
          </Outsider>
        ) : null}
      </div>
    </div>
  );
};

export default Store;
