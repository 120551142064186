import React from "react";
import { GalleryItem } from "./GalleryItem";
import PropTypes from "prop-types";

export const Gallery = ({ images }) => {
  return (
    <div className="gallery-wrapper">
      <div className="popular-stylist-header">
        <h1>ნამუშევრები</h1>
        <div className="border white"></div>
      </div>
      <div className="gallery-container">
        {images.map((item, i) => {
          return <GalleryItem image={item} key={i} images={images} />;
        })}
      </div>
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
};
