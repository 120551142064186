import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "../../styles/intro.css";

export const Intro = () => {
  const { isLogged, name } = useSelector(state => state.user);
  const txtSlickSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
  };
  const imgSlickSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  return (
    <div className="landing-intro">
      <div className="langind-text-container">
        <Slider {...txtSlickSettings}>
          <div className="landing-intro-txt">
            <div className="landing-intro-txt-wrapper">
              <h1>
                {isLogged
                  ? `მოგესალმებით, ${name.split(" ")[0]}!`
                  : "მოგესალმებით!"}
              </h1>
              <p>
                {isLogged
                  ? `დაჯავშნეთ სასურველ სტილისტთან ვიზიტი ონლაინ.`
                  : `გაიარეთ ავტორიზაცია და დაჯავშნეთ სასურველ სტილისტთან ვიზიტი ონლაინ.`}
              </p>
            </div>
          </div>
          <div className="landing-intro-txt">
            <div className="landing-intro-txt-wrapper">
              <h1>{"სილამაზის სალონი გოლდი"}</h1>
              <p>სილამაზის სალონ გოლდს, გორში სამი ფილიალი აქვს.</p>
            </div>
          </div>
          <div className="landing-intro-txt">
            <div className="landing-intro-txt-wrapper">
              <h1 style={{ fontSize: 42 }}>
                სილამაზე, ნებისმიერ დროს, ნებისმიერ ადგილას.
              </h1>
              <p>ჩვენ ვზრუნავთ თქვენს სილამაზეზე, დაჯავშნეთ ახლავე!</p>
            </div>
          </div>
        </Slider>
      </div>
      <div className="landing-intro-img-container">
        <div className="landing-intro-img">
          <img src={require("../../assets/newphoto.jpg")} alt="Landing Img" />
        </div>
      </div>
    </div>
  );
};
