import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { FaButton } from '../../parts/FaButton';
import "./Widgets.css";

export const WidgetItem = ({id, name, photo}) => {
    const [isDeleted, setIsDeleted] = useState(false);

    const deleteHandler = async () => {
        const res = await fetch(`/api/v1/widget/delete/${id}`);
        if(res.ok){
           return setIsDeleted(true)
        }
        return Swal.fire({
            icon: 'error',
            title: 'დაფიქსირდა შეცდომა'
        })
    }

    if(isDeleted){
        return null
    }

    return (
        <div className="widget-item">
            <div className="widget-item-img-container">
                <img src={photo} alt={name}/>
            </div>
            <span>{name}</span>
            <FaButton icon="Bin" onClick={deleteHandler}>წაშლა</FaButton>
        </div>
    )
}