import { useEffect, useRef, useState } from "react";

// useEffect hook that does not trigger on initial load
export const useDidUpdate = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

// Hook which sets document title on component mount
export const useTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

// Hook used for fetching initial data
export const useFetch = (api, defaultData) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [r, setr] = useState(false);
  const [data, setData] = useState(defaultData);
  const refresh = () => setr(r => !r);
  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        const res = await fetch(api);
        if (res.ok) {
          const parsed = await res.json();
          setData(parsed);
          return setLoading(false);
        }
        setLoading(false);
        return setError(true);
      })();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  }, [r, api]);
  return { data, loading, error, refresh };
};
