import React from "react";
import PropTypes from "prop-types";
import { addresses, months } from "../../../../info";
import { Lari } from "../../reusables/Lari";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useTitle } from "../../../../utils/hooks";

export const UserBooking = ({
  stylist,
  service,
  gold,
  price,
  date,
  id,
  booking,
  refresh = () => {},
}) => {
  const dateString = `${new Date(date).getDate()} ${
    months[new Date(date).getMonth()]
  }`;
  const { type } = useSelector(state => state.user);
  const deleteHandler = () => {
    Swal.fire({
      title: "დარწმუნებული ხართ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "კი",
      cancelButtonText: "გაუქმება",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await fetch(`/api/v1/booking/delete/${id}`);
        if (res.ok) {
          Swal.fire("წაშლილია!", "", "success");
          refresh();
        }
      }
    });
  };
  useTitle(booking ? "ჯავშნები" : "ისტორია");
  const goldHelper = ["I", "II", "III"];
  return (
    <div className="user-book">
      <div className={`user-book-info ${!booking ? "blue-border" : ""}`}>
        <h4>{service}</h4>
        <span>{stylist}</span>
        <span>
          გოლდი {goldHelper[gold]}, {addresses[gold]}
        </span>
        <span>
          {price}
          <Lari />
        </span>
        <span className={`date-span ${!booking ? "blue" : ""}`}>
          {dateString} {dayjs(date).format("HH:mm")}
        </span>
      </div>
      {booking && type === "user" ? (
        <img
          src={require("../../assets/trash.svg")}
          alt="cancel"
          title="გაუქმება"
          className="delete-book"
          onClick={deleteHandler}
        />
      ) : null}
    </div>
  );
};

UserBooking.propTypes = {
  stylist: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  gold: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  date: PropTypes.any.isRequired,
  booking: PropTypes.bool.isRequired,
  id: PropTypes.string,
  refresh: PropTypes.func,
};
