import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Outsider } from "../../util_comps/Wrappers";
import { AddService } from "./AddService";
import { proffesions as professions, proffesions } from "../../../info";

export const Service = ({ name, id, price, profession, refresh }) => {
  const [deleted, setDeleted] = useState(false);
  const [update, setUpdate] = useState(false);
  const { superAdmin } = useSelector(state => state.manager);
  const MySwal = withReactContent(Swal);
  const delHandler = async () => {
    MySwal.fire({
      title: "დარწმუნებული ხართ?",
      text: "სერვისი სამუდამოდ წაიშლება!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "გაუქმება",
      confirmButtonText: "კი",
    }).then(async result => {
      if (result.value) {
        const res = await fetch(`/api/v1/service/delete/${id}`);
        if (res.ok) {
          MySwal.fire("წაიშალა!", "სერვისი წარმატებით წაიშალა.", "success");
          setDeleted(true);
        } else {
          MySwal.fire("დაფიქსირდა შეცდომა!", "სცადეთ ხელახლა.", "error");
        }
      }
    });
  };
  return !deleted ? (
    <>
      {update ? (
        <Outsider onOutside={() => setUpdate(false)}>
          <AddService
            update={true}
            refresh={refresh}
            preInfo={{
              id,
              price,
              name,
              profession: professions[profession],
            }}
          />
        </Outsider>
      ) : null}
      <div className="service--">
        <div className="service-info">
          <span className="service-name">{name}</span>
          <span>{proffesions[profession]}</span>
          <span>ფასი: {price}₾</span>
        </div>
        <div className="service-func">
          {superAdmin ? (
            <>
              <button
                className="primary-button service-btn delete"
                title="სერვისის წაშლა"
                onClick={delHandler}
              >
                &nbsp;
              </button>
              <button
                className="primary-button service-btn edit"
                title="სერვისის რედაქტირება"
                onClick={() => setUpdate(true)}
              >
                &nbsp;
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
};

Service.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  profession: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
};
