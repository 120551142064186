import React, { useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { useFetch, useTitle } from "../../../utils/hooks";
import { Loading, Error } from "../../parts/PageStatuses";
import { Manager } from "./manager/Manager";
import { AddManager } from "./addManager/AddManager";
import { Outsider } from "../../util_comps/Wrappers";
import "./Managers.css";
import { FaButton } from "../../parts/FaButton";

const Managers = () => {
  const { data, error, loading, refresh } = useFetch(
    "/api/v1/manager/fetch/all",
    []
  );
  const [add, setAdd] = useState(false);
  useTitle("მენეჯერები");
  return (
    <div className="managers-page grid-page page">
      <Navigation active="/managers" />
      <div className="managers-page-content">
        {loading ? <Loading /> : null}
        {error ? <Error /> : null}
        {data.map((item, i) => {
          return (
            <Manager
              key={i}
              name={item.name}
              salary={item.salary}
              id={item._id}
              refresh={refresh}
            />
          );
        })}
        {add ? (
          <Outsider onOutside={() => setAdd(false)}>
            <AddManager update={false} refresh={refresh} />
          </Outsider>
        ) : null}
        {!loading && !error ? (
          <div className="centered">
            <FaButton
              icon="faPlusCircle"
              onClick={() => setAdd(true)}
              className="big-font"
            >
              მენეჯერის დამატება
            </FaButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Managers;
