import React, { useEffect } from "react";
import { Routing } from "./routing/Routing";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_SERVICES,
  ADD_MANAGERS,
  ADD_EMPLOYEES,
  LOG_OUT,
  ADD_REPORTS,
  ADD_STORE,
  USER_LOGOUT,
} from "./actions/actiontypes";
import { useNotification } from "./utils/listener";
import { Greeting } from "./components/greeting/Greeting";
import "./App.css";
import "./components/user/styles/reusables.css";
import "./components/user/styles/media.css";

function App() {
  const dispatch = useDispatch();
  const { superAdmin, isLogged, gold, name } = useSelector(state => state.manager);
  const { type, isLogged: userIsLogged } = useSelector(state => state.user);
  useNotification(gold, isLogged);
  useEffect(() => {
    const services = async () => {
      const res = await fetch("/api/v1/service/fetch/all");
      if (res.ok) {
        const data = await res.json();
        dispatch({
          type: ADD_SERVICES,
          payload: data,
        });
      }
    };
    const stylists = async () => {
      const res = await fetch("/api/v1/stylist/fetch/all");
      if (res.ok) {
        const data = await res.json();
        dispatch({
          type: ADD_EMPLOYEES,
          payload: data,
        });
      } else {
        dispatch({
          type: LOG_OUT,
        });
      }
    };
    const store = async () => {
      const res = await fetch(`/api/v1/store/fetch/all?gold=${gold}`);
      if (res.ok) {
        const data = await res.json();
        dispatch({
          type: ADD_STORE,
          payload: data,
        });
      }
    };
    const managers = async () => {
      const res = await fetch("/api/v1/manager/fetch/all");
      if (res.ok) {
        const data = await res.json();
        dispatch({
          type: ADD_MANAGERS,
          payload: data,
        });
      }
    };
    services();
    if (isLogged) {
      stylists();
      store();
      if (superAdmin) {
        managers();
      }
    }
    if (userIsLogged) {
      (async () => {
        const res = await fetch(
          `/api/v1/auth/check-token/${type === "user" ? "user" : "stylist"}`
        );
        if (!res.ok) {
          dispatch({
            type: USER_LOGOUT,
          });
        }
      })();
    }
  }, [dispatch, isLogged, superAdmin, gold]);
  useEffect(() => {
    const reports = async () => {
      const res = await fetch(
        `/api/v1/report/stylist?gold=${gold}&reportType=daily&page=1`
      );
      if (res.ok) {
        const data = await res.json();
        dispatch({
          type: ADD_REPORTS,
          payload: data,
        });
      }
    };
    if (isLogged) {
      reports();
    }
  }, [gold, isLogged, dispatch]);
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
