import React, { useEffect, useState } from "react";
import { Stat } from "./Stat";
import "../../styles/stats.css";

export const Stats = () => {
  const [stats, setStats] = useState([
    {
      quantity: 3,
      icon: "faHotel",
      name: "ფილიალი",
    },
    {
      quantity: 0,
      icon: "faCut",
      name: "სერვისი",
      index: "totalServices",
    },
    {
      quantity: 0,
      icon: "faUsers",
      name: "სპეციალისტი",
      index: "totalStylists",
    },
    {
      quantity: 0,
      icon: "faHeart",
      name: "ბედნიერი კლიენტი",
      index: "totalClients",
    },
  ]);
  useEffect(() => {
    (async () => {
      const res = await fetch("/api/v1/landing/totals");
      if (res.ok) {
        const data = await res.json();
        setStats(
          stats.map(item => {
            return {
              ...item,
              quantity: data[item.index] ? data[item.index] : 3,
            };
          })
        );
      }
    })();
  }, []);
  return (
    <div className="stats-container">
      {stats.map((item, i) => {
        return <Stat key={i} {...item} />;
      })}
    </div>
  );
};
