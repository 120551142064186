import React, { useState } from "react";
import { swalert } from "../../../../utils/functions";
import { LoadButton } from "../../../util_comps/LoadButton";
import PropTypes from "prop-types";
import "./AddManager.css";

export const AddManager = ({ update, preData, refresh }) => {
  const [data, setData] = useState(
    update
      ? preData
      : {
          name: "",
          password: "",
          passwordRep: "",
        }
  );
  const [loading, setLoading] = useState(false);
  const handler = e => setData({ ...data, [e.target.name]: e.target.value });
  const add = async () => {
    if (data.password === data.passwordRep) {
      setLoading(true);
      const res = await fetch(
        `/api/v1/manager/${update ? `update/creds/${data.id}` : "add"}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            update
              ? {
                  name: data.name,
                }
              : {
                  name: data.name,
                  password: data.password,
                  salary: 0,
                  managerStatus: "normal",
                }
          ),
        }
      );
      if (res.ok) {
        setLoading(false);
        swalert({
          icon: "success",
          text: `მენეჯერი წარმატებით ${update ? "განახლდა" : "დაემატა"}!`,
        });
        refresh();
      } else {
        setLoading(false);
        swalert({
          icon: "error",
          text: "დაფიქსირდა შეცდომა",
        });
      }
    } else {
      swalert({
        icon: "error",
        text: "პაროლები ერთმანეთს არ ემთხვევა",
      });
    }
  };
  return (
    <div className="modal-container">
      <h3>ახალი მენეჯერი</h3>
      <div className="modal-input">
        <span>სახელი და გვარი:</span>
        <input type="text" value={data.name} name="name" onChange={handler} />
      </div>
      {!update ? (
        <>
          <div className="modal-input">
            <span>პაროლი:</span>
            <input
              type="password"
              value={data.password}
              name="password"
              onChange={handler}
            />
          </div>
          <div className="modal-input">
            <span>გაიმეორეთ პაროლი:</span>
            <input
              type="password"
              value={data.passwordRep}
              name="passwordRep"
              onChange={handler}
            />
          </div>{" "}
        </>
      ) : null}
      <LoadButton
        text={update ? "განახლება" : "დამატება"}
        loading={loading}
        onClick={add}
      />
    </div>
  );
};

AddManager.propTypes = {
  update: PropTypes.bool.isRequired,
  preData: PropTypes.object,
  refresh: PropTypes.func.isRequired,
};
