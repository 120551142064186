import React, { useState, useEffect } from "react";
import { Input } from "../../reusables/Input";
import { Link } from "react-router-dom";
import { post } from "../../utils/utils";
import { UserLoadButton } from "../../reusables/UserLoadButton";
import { swalert } from "../../../../utils/functions";
import { useDispatch } from "react-redux";
import { UserModal } from "../../reusables/UserModal";
import { USER_LOGIN } from "../../../../actions/actiontypes";
import { useTitle } from "../../../../utils/hooks";

export const Signup = () => {
  const dispatch = useDispatch();
  useTitle("რეგისტრაცია");
  const [inputInfo, setInputInfo] = useState({
    nameAndSurname: "",
    number: "",
    password: "",
    repeatPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [code, setCode] = useState("");
  useEffect(() => {
    // If user has typed different passwords
    if (inputInfo.password !== inputInfo.repeatPassword) {
      if (
        inputInfo.password.length >= 6 &&
        inputInfo.repeatPassword.length >= 6
      )
        if (!invalidInput) setInvalidInput(true);
    } else {
      if (invalidInput) setInvalidInput(false);
    }
  }, [inputInfo.password, inputInfo.repeatPassword]);
  const signup = async e => {
    e.preventDefault();
    // If password and password repeat are the same
    if (inputInfo.password === inputInfo.repeatPassword) {
      setLoading(true);
      const res = await post("/api/v1/auth/user/sign-up", {
        name: inputInfo.nameAndSurname,
        number: inputInfo.number,
        password: inputInfo.password,
      });
      if (res.ok) {
        setSent(true);
      } else {
        swalert({
          icon: "error",
          text: "დაფიქსირდა შეცდომა",
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
      swalert({
        icon: "error",
        text: "პაროლები არ ემთხვევა",
      });
    }
  };
  // Onchange event handler for signup inputs
  const onChange = e => {
    // Copy target propery because it's not accessible from functional setter
    const target = e.target;
    setInputInfo(prevState => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  const verifyHandler = async e => {
    e.preventDefault();
    const res = await fetch(
      `/api/v1/auth/user/verify?code=${code}&number=${inputInfo.number}`
    );
    if (res.ok) {
      swalert({
        icon: "success",
        text: "თქვენ წარმატებით გაიარეთ რეგისტრაცია",
      });
      const data = await res.json();
      dispatch({
        type: USER_LOGIN,
        payload: data,
      });
    } else {
      swalert({ icon: "error", text: "დაფიქსირდა შეცდომა" });
    }
  };
  return (
    <form className="auth-form--">
      {sent ? (
        <UserModal onClose={() => setSent(false)}>
          <h2 style={{ textAlign: "center" }}>
            შეიყვანეთ მობილურზე მოსული ხუთნიშნა კოდი
          </h2>
          <Input
            type="text"
            placeholder="კოდი"
            icon="faComment"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          <UserLoadButton
            text="OK"
            loading={loading}
            style={{ marginTop: "1.5rem" }}
            onClick={verifyHandler}
          />
        </UserModal>
      ) : null}
      <h1>რეგისტრაცია</h1>
      <Input
        icon="faUserAlt"
        type="text"
        name="nameAndSurname"
        placeholder="სახელი და გვარი"
        onChange={onChange}
        value={inputInfo.nameAndSurname}
      />
      <Input
        icon="faPhone"
        type="text"
        name="number"
        placeholder="ტელეფონი"
        onChange={onChange}
        value={inputInfo.number}
      />
      <Input
        type="password"
        icon="faLock"
        name="password"
        placeholder="პაროლი"
        invalid={invalidInput}
        onChange={onChange}
        value={inputInfo.password}
      />
      <Input
        type="password"
        icon="faKey"
        name="repeatPassword"
        placeholder="გაიმეორეთ პაროლი"
        invalid={invalidInput}
        onChange={onChange}
        value={inputInfo.repeatPassword}
      />
      <div className="auth-btn-container">
        <UserLoadButton text="რეგისტრაცია" loading={loading} onClick={signup} />
        <span>რეგისტრაციის გავლით ეთანხმებით საიტის <Link to="/terms">წესებსა და პირობებს</Link></span>
      </div>
    </form>
  );
};
