import React from "react";
import PropTypes from "prop-types";
import { Lari } from "../reusables/Lari";

export const SalaryItem = ({ records, totalEarned }) => {
  return (
    <div className="salary-item-container">
      <span className="margined-span">სერვისი: {records}</span>
      <span>
        თანხა: {totalEarned}
        <Lari />
      </span>
    </div>
  );
};

SalaryItem.propTypes = {
  records: PropTypes.number.isRequired,
  totalEarned: PropTypes.number.isRequired,
};
