import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const UserAuthorized = ({ render, path }) => {
  const { isLogged, type } = useSelector(state => state.user);
  return isLogged && type === "user" ? (
    <Route exact path={path} render={render} />
  ) : (
    <Redirect to="/" />
  );
};

UserAuthorized.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
