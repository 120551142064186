import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserModal } from "../reusables/UserModal";

export const GalleryItem = ({ image, images }) => {
  const [expanded, setExpanded] = useState(false);
  const [img, setImg] = useState(image);
  const next = () => {
    let index = images.indexOf(img);
    if (index === images.length - 1) {
      return setImg(images[0]);
    }
    setImg(images[index + 1]);
  };
  const prev = () => {
    let index = images.indexOf(img);
    if (index === 0) {
      return setImg(images[images.length - 1]);
    }
    setImg(images[index - 1]);
  };
  useEffect(() => {
    setImg(image);
  }, [expanded]);
  return (
    <>
      {expanded ? (
        <UserModal className="transparent" onClose={() => setExpanded(false)}>
          <div className="gallery-item-content">
            <button onClick={prev}>
              <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" size="2x" />
            </button>
            <img src={img} />
            <button onClick={next}>
              <FontAwesomeIcon icon={faArrowRight} color="#ffffff" size="2x" />
            </button>
          </div>
        </UserModal>
      ) : null}
      <div className="gallery-item">
        <img src={image} alt="not found" onClick={() => setExpanded(true)} />
      </div>
    </>
  );
};

GalleryItem.propTypes = {
  image: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
};
