import React, { useState, useEffect } from "react";
import { Navigation } from "../navigation/Navigation";
import { Search } from "../../util_comps/Search";
import { useTitle, useDidUpdate } from "../../../utils/hooks";
import { Record } from "./record/Record";
import { Redirect } from "react-router-dom";
import { Loading, Error } from "../../util_comps/InfStatus";
import { useSelector } from "react-redux";
import "./Records.css";
import { FaButton } from "../../parts/FaButton";

const Records = () => {
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [scroll, setScroll] = useState({
    page: 1,
    loading: false,
  });
  const gold = useSelector(state => state.manager.gold);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ records: [], hasMore: true });
  const [error, setError] = useState(false);
  useTitle("ჩანაწერები");
  useDidUpdate(() => {
    setRedirect(true);
  }, [gold]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await fetch(
        `/api/v1/record/fetch/all?gold=${gold}&page=${scroll.page}${
          search ? `&keyword=${search}` : ""
        }`
      );
      if (res.ok) {
        const info = await res.json();
        setData({
          records: [...data.records, ...info.records],
          hasMore: info.hasMore,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    })();
  }, [scroll.page, refresh]);
  const onSearch = () => {
    setSearch(searchValue);
    setData({ records: [], hasMore: true });
    setScroll({ page: 1, loading: false });
    setRefresh(!refresh);
  };
  const loadMore = () => {
    setScroll(prevState => ({ ...scroll, page: prevState.page + 1 }));
  };
  return (
    <div className="grid-page records-page page">
      <Navigation active="/records" />
      {redirect ? <Redirect to="/records" /> : null}
      <div className="records-page-content">
        <Search
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder="საძიებო სიტყვა"
          onSearch={onSearch}
          loading={scroll.loading}
        />
        <table>
          <tbody>
            <tr className="tr-header">
              <th>სტილისტი</th>
              <th>მომხმარებელი</th>
              <th>ტელეფონი</th>
              <th>სერვისი</th>
              <th>ფასი</th>
              <th>თარიღი</th>
              <th>გადახდა</th>
              <th>მენეჯერი</th>
            </tr>
            {data.records.map((item, i) => {
              return (
                <Record
                  key={i}
                  user={item.client?.name}
                  manager={item.manager?.name}
                  clientNumber={item.client?.number}
                  amount={item.payment?.amount}
                  id={item._id}
                  date={item.createdAt}
                  services={item.stylist.services}
                  stylist={item.stylist?.name}
                  service={item.service?.name}
                  profession={item.stylist?.profession}
                  paymentMethod={item.payment?.paymentMethod}
                  item={item}
                  storeItems={item.storeItems.map(item => {
                    return {
                      spent: item.spent,
                      name: item.item.name,
                      _id: item.item._id,
                      brand: item.item.brand,
                    };
                  })}
                />
              );
            })}
          </tbody>
        </table>
        {data.hasMore && !loading ? (
          <div className="centered">
            <FaButton
              icon="faChevronCircleDown"
              onClick={loadMore}
              className="big-font"
            >
              მეტის ჩატვირთვა
            </FaButton>
          </div>
        ) : null}
        {loading ? <Loading /> : null}
        {error ? <Error /> : null}
      </div>
    </div>
  );
};

export default Records;
