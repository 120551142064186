import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

export const CustomLink = ({ children, to, scroll = true }) => {
  const history = useHistory();
  const clickHandler = () => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
    history.push(to);
  };
  return (
    <div onClick={clickHandler} style={{ cursor: "pointer" }}>
      {children}
    </div>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  scroll: PropTypes.bool,
};
