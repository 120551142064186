import React, { useState } from "react";
import { swalert } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { proffesions } from "../../../info";
import PropTypes from "prop-types";
import "./Services.css";
import { ADD_SERVICES } from "../../../actions/actiontypes";
import { LoadButton } from "../../util_comps/LoadButton";
import { FaButton } from "../../parts/FaButton";

export const AddService = ({ update, preInfo, refresh }) => {
  const [info, setInfo] = useState(
    update
      ? preInfo
      : {
          name: "",
          price: "",
          profession: "სტილისტი",
        }
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const changeHandler = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const addHandler = async () => {
    setLoading(true);
    let index = proffesions.indexOf(info.profession);
    const res = await fetch(
      `/api/v1/service/${update ? `update/${preInfo.id}` : "add"}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profession: index,
          name: info.name,
          price: parseInt(info.price),
        }),
      }
    );
    if (res.ok) {
      setLoading(false);
      swalert({
        icon: "success",
        text: `სერვისი წარმატებით ${update ? "განახლდა" : "დაემატა"}!`,
      });
      refresh();
      (async () => {
        const res = await fetch("/api/v1/service/fetch/all");
        if (res.ok) {
          const data = await res.json();
          dispatch({
            type: ADD_SERVICES,
            payload: data,
          });
        }
      })();
    } else {
      setLoading(false);
      swalert({
        icon: "error",
        text: "დაფიქსირდა შეცდომა!",
      });
    }
  };
  return (
    <div className="modal-container add-service-modal">
      <h3>{update ? "სერვისის განახლება" : "სერვისის დამატება"}</h3>
      <div className="modal-input">
        <span>სერვისის სახელი:</span>
        <input
          type="text"
          name="name"
          value={info.name}
          onChange={changeHandler}
        />
      </div>
      <div className="modal-input">
        <span>სერვისის ფასი:</span>
        <input
          type="number"
          min="0"
          name="price"
          value={info.price}
          onChange={changeHandler}
        />
      </div>
      <div className="modal-input">
        <span>პროფსია:</span>
        <select
          disabled={update}
          name="profession"
          value={info.profession}
          onChange={changeHandler}
        >
          <option>სტილისტი</option>
          <option>მანიკურის სპეციალისტი</option>
          <option>ვიზაჟის სპეციალისტი</option>
          <option>კოსმეტოლოგი</option>
        </select>
      </div>
      <FaButton
        className="add-service-btn big-font"
        icon="faConciergeBell"
        onClick={addHandler}
        disabled={loading}
      >
        {update ? "განახლება" : "დამატება"}
      </FaButton>
    </div>
  );
};

AddService.propTypes = {
  update: PropTypes.bool.isRequired,
  preInfo: PropTypes.object,
  refresh: PropTypes.func.isRequired,
};
