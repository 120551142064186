import { ADD_SERVICES } from "../actions/actiontypes";

const initialState = [];

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SERVICES:
      return state.length === 0 ? [...state, ...action.payload] : [...state];
    default:
      return [...state];
  }
};

export default mainReducer;
