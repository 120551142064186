import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { swalert } from "../../../../utils/functions";
import { useSelector, useDispatch } from "react-redux";
import { useDidUpdate } from "../../../../utils/hooks";
import { Fade } from "../../../transitions/Fade";
import { StoreRecord } from "./StoreRecord";
import "./Record.css";
import { ADD_REPORTS } from "../../../../actions/actiontypes";

export const Record = ({
  manager,
  stylist,
  id,
  user,
  date,
  service,
  clientNumber,
  amount,
  item,
  paymentMethod,
  profession,
  storeItems,
}) => {
  const paymentHelper = ["ნაღდი", "ტერმინალი", "ვაუჩერი"];
  const [info, setInfo] = useState({
    stylistid: item?.stylist?._id,
    serviceid: item?.service?._id,
    managerid: item?.manager?._id,
    manager,
    stylist,
    price: amount,
    user,
    date,
    clientNumber,
    paymentMethod,
    service,
    storeItems,
    profession,
  });
  const dispatch = useDispatch();
  const { employees } = useSelector(state => state.managerInfo);
  const { superAdmin } = useSelector(state => state.manager);
  const [expanded, setExpanded] = useState(false);
  const services = useSelector(state => state.services);
  const store = useSelector(state => state.managerInfo.store);
  const gold = useSelector(state => state.manager.gold);
  const MySwal = withReactContent(Swal);
  const [edit, setEdit] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const handler = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const onSelectChange = e => {
    if (e.target.name === "serviceid") {
      setInfo({
        ...info,
        [e.target.name]: e.target.selectedOptions[0].getAttribute(
          "data-stylistid"
        ),
        [e.target.getAttribute("data-status")]: e.target.value,
        price: e.target.selectedOptions[0].getAttribute("data-price"),
      });
    } else {
      setInfo({
        ...info,
        [e.target.name]: e.target.selectedOptions[0].getAttribute(
          "data-stylistid"
        ),
        profession: parseInt(
          e.target.selectedOptions[0].getAttribute("data-profession")
        ),
        [e.target.getAttribute("data-status")]: e.target.value,
      });
    }
  };
  useDidUpdate(() => {
    const s = services.filter(item => item.profession === info.profession)[0];
    setInfo(prevState => ({
      ...prevState,
      serviceid: s._id,
      service: s.name,
      price: s.price,
    }));
  }, [info.profession]);
  const wasChanged = useRef(false);
  useDidUpdate(() => {
    wasChanged.current = true;
  }, [info]);
  const editHandler = async () => {
    const res = await fetch(`/api/v1/record/update/${id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stylist: info?.stylistid,
        service: info?.serviceid,
        clientNumber: info?.clientNumber,
        clientName: info?.user,
        paymentAmount: parseInt(info.price),
        paymentMethod: parseInt(info.paymentMethod),
        storeItems: info.storeItems.map(item => {
          return {
            ...item,
            item: item._id,
          };
        }),
      }),
    });
    if (res.ok) {
      swalert({
        icon: "success",
        text: "ჩანაწერი წარმატებით განახლდა",
      });
      (async () => {
        const res = await fetch(
          `/api/v1/report/stylist?gold=${gold}&reportType=daily&page=1`
        );
        if (res.ok) {
          const data = await res.json();
          dispatch({
            type: ADD_REPORTS,
            payload: data,
          });
        }
      })();
    } else {
      setInfo({
        ...info,
        stylistid: item?.stylist?._id,
        serviceid: item?.service?._id,
        managerid: item?.manager?._id,
        manager,
        stylist,
        price: amount,
        user,
        date,
        clientNumber,
        paymentMethod,
        service,
        storeItems,
      });
      swalert({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  useDidUpdate(() => {
    if (!edit && wasChanged) {
      editHandler();
    }
  }, [edit]);
  const deleteHandler = async () => {
    MySwal.fire({
      title: "დარწმუნებული ხართ?",
      text: "ჩანაწერი სამუდამოდ წაიშლება!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "გაუქმება",
      confirmButtonText: "კი",
    }).then(async result => {
      if (result.value) {
        const res = await fetch(`/api/v1/record/delete/${id}`);
        if (res.ok) {
          MySwal.fire("წაიშალა!", "ჩანაწერი წარმატებით წაიშალა.", "success");
          setDeleted(true);
          const _res = await fetch(
            `/api/v1/report/stylist?gold=${gold}&reportType=daily&page=1`
          );
          if (_res.ok) {
            const data = await _res.json();
            dispatch({
              type: ADD_REPORTS,
              payload: data,
            });
          }
        } else {
          MySwal.fire("დაფიქსირდა შეცდომა!", "სცადეთ ხელახლა.", "error");
        }
      }
    });
  };
  const onInputChange = (x, val) => {
    const clone = info.storeItems.map(item => {
      if (item._id === x) {
        return {
          ...item,
          spent: Number(val),
        };
      }
      return item;
    });
    setInfo({ ...info, storeItems: clone });
  };
  const onSelectValueChange = (x, val) => {
    const clone = info.storeItems.map((item, i) => {
      if (x === i) {
        return {
          ...item,
          name: val.name,
          _id: val._id,
          brand: val.brand,
        };
      }
      return item;
    });
    setInfo({ ...info, storeItems: clone });
  };
  const delHandler = id => {
    const clone = info.storeItems.filter(item => item._id !== id);
    setInfo({ ...info, storeItems: clone });
  };
  const appendStoreItem = () => {
    let item = store[0];
    item = {
      name: item.name,
      brand: item.brand,
      spent: 0,
      _id: item._id,
    };
    setInfo({
      ...info,
      storeItems: [...info.storeItems, item],
    });
  };
  return deleted ? null : (
    <>
      <tr className="record-tr">
        <td onClick={() => setExpanded(!expanded)} className="pointer">
          {edit ? (
            <select
              value={info.stylist}
              onChange={onSelectChange}
              data-status="stylist"
              name="stylistid"
            >
              {employees
                .filter(item => item.gold === gold)
                .map((item, i) => (
                  <option
                    key={i}
                    data-stylistid={item._id}
                    data-profession={item.profession}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
          ) : (
            info.stylist
          )}
        </td>
        <td>
          {edit ? (
            <input name="user" value={info.user} onChange={handler} />
          ) : (
            info.user
          )}
        </td>
        <td>
          {edit ? (
            <input
              name="clientNumber"
              className="px70"
              value={info.clientNumber}
              onChange={handler}
            />
          ) : (
            info.clientNumber
          )}
        </td>
        <td>
          {edit ? (
            <select
              value={info.service}
              onChange={onSelectChange}
              data-status="service"
              name="serviceid"
            >
              {services
                .filter(item => item.profession === info.profession)
                .map((item, i) => (
                  <option
                    key={i}
                    data-stylistid={item._id}
                    data-price={item.price}
                  >
                    {item.name.length >= 25
                      ? item.name.substring(0, 23) + "..."
                      : item.name}
                  </option>
                ))}
            </select>
          ) : (
            info.service
          )}
        </td>
        <td className="price-td">
          {edit ? (
            <input name="price" value={info.price} onChange={handler} />
          ) : (
            info.price
          )}
        </td>
        <td>{moment(date).format("DD/MM/YY HH:mm")}</td>
        <td>
          {!edit ? (
            paymentHelper[info.paymentMethod]
          ) : (
            <select
              value={paymentHelper[info.paymentMethod]}
              onChange={e => {
                setInfo({
                  ...info,
                  paymentMethod: Number(
                    e.target.selectedOptions[0].getAttribute("data-pay")
                  ),
                  pay: e.target.value,
                });
              }}
            >
              <option data-pay="0" value="ნაღდი">
                ნაღდი
              </option>
              <option data-pay="1" value="ტერმინალი">
                ტერმინალი
              </option>
              <option data-pay="2" value="ვაუჩერი">
                ვაუჩერი
              </option>
            </select>
          )}
        </td>
        <td className="flex-td">
          {/*edit ? (
            <select
              value={info.manager}
              onChange={onSelectChange}
              data-status="manager"
              name="managerid"
            >
              {managers.map((item, i) => (
                <option key={i} data-stylistid={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
              ) */}
          {info.manager}
          <div style={{ display: "flex" }}>
            {superAdmin ? (
              <>
                <img
                  src={edit ? require("./save.svg") : require("./edit.svg")}
                  alt="edit"
                  className="tr-edit"
                  onClick={() => setEdit(!edit)}
                />
                <img
                  src={require("./delete.svg")}
                  alt="delete"
                  className="tr-delete"
                  onClick={deleteHandler}
                />
              </>
            ) : null}
          </div>
        </td>
      </tr>
      <tbody className="select-container">
        <Fade inprop={expanded || edit} td={true} className="block">
          {info.storeItems.map((item, i) => (
            <StoreRecord
              onChange={onInputChange}
              key={i}
              edit={edit}
              id={item._id}
              index={i}
              selectValue={item.name}
              value={item.spent}
              onSelectValueChange={onSelectValueChange}
              delHandler={delHandler}
            />
          ))}
          {edit ? <button onClick={appendStoreItem}>დამატება</button> : null}
        </Fade>
      </tbody>
    </>
  );
};

Record.propTypes = {
  manager: PropTypes.string.isRequired,
  stylist: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  clientNumber: PropTypes.any.isRequired,
  amount: PropTypes.number.isRequired,
  paymentMethod: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  storeItems: PropTypes.array.isRequired,
  profession: PropTypes.any.isRequired,
};
