import React, { useState } from "react";
import { Input } from "../../reusables/Input";
import { UserLoadButton } from "../../reusables/UserLoadButton";
import { Link } from "react-router-dom";
import { post } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { swalert } from "../../../../utils/functions";
import { USER_LOGIN } from "../../../../actions/actiontypes";
import { useTitle } from "../../../../utils/hooks";

export const Signin = () => {
  useTitle("შესვლა");
  const dispatch = useDispatch();
  const [inputInfo, setInputInfo] = useState({
    number: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const loginHandler = async e => {
    e.preventDefault();
    setLoading(true);
    // Determine if string is telephone number (if user type is user)
    const reg = new RegExp(/[0-9]\+?\d+/g);
    const isNumber = reg.test(inputInfo.number);
    const res = await post(
      isNumber ? "/api/v1/auth/user/sign-in" : "/api/v1/auth/stylist/sign-in",
      isNumber
        ? {
            number: inputInfo.number,
            password: inputInfo.password,
          }
        : {
            name: inputInfo.number,
            password: inputInfo.password,
          }
    );
    if (res.ok) {
      const data = await res.json();
      setLoading(false);
      dispatch({
        type: USER_LOGIN,
        payload: { ...data, type: isNumber ? "user" : "stylist" },
      });
    } else {
      setLoading(false);
      swalert({ icon: "error", text: "ნომერი ან პაროლი არასწორია" });
    }
  };
  // Onchange event handler for signup inputs
  const onChange = e => {
    // Copy target propery because it's not accessible from functional setter
    const target = e.target;
    setInputInfo(prevState => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };
  return (
    <form className="auth-form--" onSubmit={loginHandler}>
      <h1>შესვლა</h1>
      <Input
        icon="faPhone"
        type="text"
        name="number"
        placeholder="ტელეფონი"
        onChange={onChange}
        value={inputInfo.number}
      />
      <Input
        type="password"
        icon="faLock"
        name="password"
        placeholder="პაროლი"
        onChange={onChange}
        value={inputInfo.password}
      />
      <Link to="/recovery">
        <span className="link">დაგავიწყდა პაროლი?</span>
      </Link>
      <div className="auth-btn-container">
        <UserLoadButton loading={loading} text="შესვლა" />
      </div>
    </form>
  );
};
