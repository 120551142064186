import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const UserUnauthorized = ({ render, path, exact=true }) => {
  const isLogged = useSelector(state => state.user.isLogged);
  return !isLogged ? (
    <Route
      exact={exact}
      path={path}
      render={render}
    />
  ) : (
    <Redirect to="/" />
  );
};

UserUnauthorized.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool
};
