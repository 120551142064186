import React, { useState, useEffect } from "react";
import { UserModal } from "../reusables/UserModal";
import { hours, months } from "../../../info";
import { Calendar } from "react-date-range";
import { isBadTime, pop } from "../../../utils/functions";
import { Lari } from "../reusables/Lari";
import { containsObject, post } from "../utils/utils";
import { useFetch } from "../utils/hooks";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export const Booking = ({ onClose, services, stylistId, gold }) => {
  const [loading, setLoading] = useState(false);
  const now = new Date();
  // Add one day to current date
  now.setDate(now.getDate() + 1);
  // If salon finished working, min date will be tomorrow's date.
  const dt = isBadTime() ? now : new Date();
  const [bookAtIndex, setBookAtIndex] = useState(null);
  const [service, setService] = useState(services[0]);
  const [bookDate, setBookDate] = useState(dt);
  const [time, setTime] = useState("");
  const { data: freeHours } = useFetch(
    `/api/v1/booking/free/${stylistId}?date=${bookDate}`,
    []
  );
  useEffect(() => {
    // Remove booking time whenever date changes
    setTime("");
    setBookAtIndex(null);
  }, [bookDate]);
  // Service change handler
  const onServiceChange = (e) => {
    setService({
      price: e.target.selectedOptions[0].getAttribute("data-price"),
      name: e.target.value,
      _id: e.target.selectedOptions[0].getAttribute("data-id"),
    });
  };
  // Booking handler
  const bookHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await post("/api/v1/booking/add/user", {
      stylistId: stylistId,
      serviceId: service._id,
      bookedDate: bookDate,
      bookedAtIndex: bookAtIndex,
      gold: gold,
    });
    if (res.ok) {
      pop({
        icon: "success",
        title: "ჯავშანი გაიგზავნა",
        text: "მენეჯერი მალე დაადასტურებს თქვენს ჯავშანს!",
      });
    } else {
      pop({
        icon: "error",
        title: "დაფიქსირდა შეცდომა",
        text: "სცადეთ მოგვიანებით!",
      });
    }
    setLoading(false);
  };
  return (
    <UserModal onClose={onClose} className="book-modal">
      <h1 style={{ color: "#fff" }} className="book-modal-header">
        {bookDate.getDate()} {months[bookDate.getMonth()]}
        {time ? ` - ${time}` : ""}
        {` - ${service.price}`}
        <Lari />
      </h1>
      <div className="book-service">
        სერვისი:
        <select onChange={onServiceChange}>
          {services.map((item, i) => {
            return (
              <option
                key={i}
                value={item.name}
                data-id={item._id}
                data-price={item.price}
              >
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="book-container">
        <div className="book-hours">
          {Object.keys(hours).map((item, i) => {
            // Get hour index of item to compare
            let hour = hours[item].h;
            let obj = hours[Number(item)];
            // Format the time string
            let minute = obj.m.toString().length === 1 ? `${obj.m}0` : obj.m;
            let h = obj.h.toString().length === 1 ? `0${obj.h}` : obj.h;
            // Get current hour
            let currentHour = now.getHours();
            let isToday = () => {
              // If user wants to book on current day, remove times before current time
              if (new Date().getDate() === bookDate.getDate()) {
                return currentHour < hour;
              }
              return true;
            };
            return isToday() ? (
              <div
                className={`book-hour ${
                  bookAtIndex === Number(item) ? "active-time" : ""
                } ${!containsObject(obj, freeHours) ? "booked-hour" : ""}`}
                key={i}
                onClick={() => {
                  setTime(`${h}:${minute}`);
                  setBookAtIndex(Number(item));
                }}
              >
                {h}:{minute}
              </div>
            ) : null;
          })}
        </div>
        <div className="book-calendar">
          <Calendar minDate={dt} date={bookDate} onChange={setBookDate} />
        </div>
      </div>
      <button
        className="golden-button"
        onClick={bookHandler}
        disabled={loading}
      >
        დაჯავშნა
      </button>
    </UserModal>
  );
};

Booking.propTypes = {
  onClose: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  stylistId: PropTypes.string.isRequired,
  gold: PropTypes.number.isRequired,
};
