import React from "react";
import PropTypes from "prop-types";
import { Lari } from "../../reusables/Lari";

export const UserService = ({ name, price, src }) => {
  return (
    <div className="user-service">
      <img src={src} alt={name} className="service-image" />
      <div className="user-service-info">
        <p>{name}</p>
        <p>
          {price}
          <Lari />
        </p>
      </div>
    </div>
  );
};

UserService.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
};
