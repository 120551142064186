import React, { useState, useEffect } from "react";
import { Navigation } from "../navigation/Navigation";
import { useDidUpdate } from "../../../utils/hooks";
import { isMoreThanOneWeek, shortify } from "../../../utils/functions";
import { useSelector } from "react-redux";
import moment from "moment";
import { TotalSwitch } from "./TotalSwitch";
import "./Total.css";
import { FaButton } from "../../parts/FaButton";

const Total = () => {
  const statusHelper = ["daily", "weekly", "monthly"];
  const momentHelper = ["days", "weeks", "months"];
  const { gold, superAdmin } = useSelector(state => state.manager);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState({ reports: [], hasMore: false });
  const [page, setPage] = useState({ i: 1, r: false });
  const [loading, setLoading] = useState(false);
  const fetchMore = async i => {
    setLoading(true);
    const res = await fetch(
      `/api/v1/report/total?gold=${gold}&page=${i}&reportType=${statusHelper[status]}`
    );
    if (res.ok) {
      const x = await res.json();
      if (x.report.length !== 0) {
        setData({
          reports:
            data.reports.length !== 0
              ? [...data.reports, x.report]
              : [x.report],
          hasMore: x.hasMore,
        });
      } else {
        setData({ ...data, hasMore: x.hasMore });
      }
    }
    setLoading(false);
  };
  useDidUpdate(() => {
    setData({ reports: [], hasMore: true });
    setPage({ i: 1, r: !page.r });
  }, [status, gold]);
  useEffect(() => {
    fetchMore(page.i);
  }, [page]);
  return (
    <div className="grid-page page total-page">
      <Navigation active="/total" />
      <div className="records-page-content">
        <TotalSwitch
          textes={["დღიური", "კვირეული", "თვიური"]}
          status={status}
          setStatus={setStatus}
          disabled={superAdmin ? 3 : 2}
        />
        {data.reports.map((item, i) => (
          <React.Fragment key={i}>
            <h3 style={{ textAlign: "center", color: "#fff" }}>
              {moment()
                .subtract(i, momentHelper[status])
                .format(status === 0 ? "DD/MM/YYYY" : "MM/YYYY")}
            </h3>
            <table key={i}>
              <tbody>
                <tr className="tr-header">
                  <th>შემოსავალი</th>
                  <th>გასავალი</th>
                  <th>მოგება</th>
                  <th>ტერმინალი</th>
                  <th>საწყობის ხარჯი</th>
                  <th>სტილისტის ხელფასები</th>
                  <th>გაყიდვები</th>
                </tr>
                {item.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{shortify(item.netIncome)}₾</td>
                      <td>
                        {shortify(item.storeItemsSpent + item.stylistSalaries)}₾
                      </td>
                      <td>
                        {shortify(
                          item.netIncome -
                            item.storeItemsSpent -
                            item.stylistSalaries
                        )}
                        ₾
                      </td>
                      <td>{shortify(item.paidOnTerminal)}₾</td>
                      <td>{shortify(item.storeItemsSpent)}₾</td>
                      <td>{shortify(item.stylistSalaries)}₾</td>
                      <td>{shortify(item.storeItemRecordsIncome)}₾</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </React.Fragment>
        ))}
        {data.hasMore &&
        !loading &&
        !isMoreThanOneWeek(momentHelper[status], page.i, superAdmin) ? (
          <div className="centered">
            <FaButton
              icon="faChevronCircleDown"
              onClick={() => setPage({ i: page.i + 1, r: !page.r })}
              className="big-font"
            >
              მეტის ჩატვირთვა
            </FaButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Total;
