import React from "react";
import { NavBar } from "../navbar/NavBar";
import { useHistory } from "react-router-dom";
import icon from "../assets/404.png";
import "../styles/smallPages.css";

const NotFound = () => {
  const history = useHistory();
  return (
    <div className="page dark notfound-page">
      <NavBar />
      <div className="notfound-icon">
        <img src={icon} alt="error 404" />
        <h2>გვერდი ვერ მოიძებნა</h2>
      </div>
      <div className="notfound-button-container">
        <button
          className="user-primary-button"
          onClick={() => history.push('/')}
        >
          დაბრუნება
        </button>
      </div>
    </div>
  );
};

export default NotFound;
