import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Booking } from "./Booking";
import Swal from "sweetalert2";

export const StylistInfo = ({
  name,
  profession,
  image,
  professionIndex,
  id,
  isFree,
  gold,
}) => {
  const [book, setBook] = useState(false);
  const history = useHistory();
  const numHelper = ["I", "II", "III"];
  const { type, isLogged } = useSelector(state => state.user);
  const services = useSelector(state => state.services);
  const loginAlert = () => {
    Swal.fire({
      icon: "info",
      text: "გაიარეთ ავტორიზაცია",
    }).then(result => {
      if (result.isConfirmed) {
        history.push("/sign-in");
      }
    });
  };
  return (
    <div className="stylist-info-grid">
      <div className="stylist-info">
        {book ? (
          <Booking
            onClose={() => setBook(false)}
            services={services.filter(
              item => item.profession === professionIndex
            )}
            stylistId={id}
            gold={gold}
          />
        ) : null}
        <div className="stylist-bio">
          <p className="stylist-name">
            {name.split(" ")[0]}
            <br />
            {name.split(" ")[1]}
          </p>
          <h3>
            {profession} - გოლდი {numHelper[gold]}
          </h3>
          {type !== "stylist" ? (
            <button
              className="golden-button"
              style={{ marginTop: 20 }}
              onClick={() => (isLogged ? setBook(true) : loginAlert())}
            >
              დაჯავშნა
            </button>
          ) : null}
          <span
            className="stylist-status"
            style={{ color: isFree ? "green" : "red" }}
          >
            ამჟამად {isFree ? "თავისუფალია" : "დაკავებულია"}
          </span>
        </div>
      </div>
      <div
        className="stylist-img"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    </div>
  );
};

StylistInfo.propTypes = {
  name: PropTypes.string.isRequired,
  profession: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  booked: PropTypes.array.isRequired,
  professionIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isFree: PropTypes.bool.isRequired,
  gold: PropTypes.number.isRequired,
};
