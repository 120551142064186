import React from "react";
import PropTypes from "prop-types";

export const UserLoadButton = ({ text, className = "", loading, ...rest}) => {
  return (
    <button
      className={`user-primary-button ${className} ${
        loading ? "user-load-button" : ""
      }`}
      {...rest}
    >
      {text}
    </button>
  );
};

UserLoadButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};
