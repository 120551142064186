import React from "react";
import PropTypes from "prop-types";
import { shortify } from "../../../../utils/functions";
import "./Report.css";

export const Report = ({ stylist, services, pay, storeItemsSpent }) => {
  return (
    <tr>
      <td>{stylist}</td>
      {typeof services !== "undefined" ? <td>{services}</td> : null}
      <td>{shortify(pay)}₾</td>
      {typeof storeItemsSpent !== "undefined" ? (
        <td>{shortify(storeItemsSpent)}₾</td>
      ) : null}
    </tr>
  );
};

Report.propTypes = {
  stylist: PropTypes.string.isRequired,
  services: PropTypes.number.isRequired,
  pay: PropTypes.number.isRequired,
  storeItemsSpent: PropTypes.number.isRequired,
};
