import React, { useState } from "react";
import { pop } from "../../../utils/functions";
import { FaButton } from "../../parts/FaButton";
import { post } from "../../user/utils/utils";
import { Navigation } from "../navigation/Navigation";
import "./Message.css";

const Message = () => {
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const sendMessage = async e => {
    e.preventDefault();
    const res = await post("/api/v1/campaign/initiate", {
      message: message,
      amount: Number(amount)
    });
    if (res.ok) {
      pop({
        icon: "success",
        text: "მესიჯების გაგზავნა დაიწყო",
      });
    } else {
      pop({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  return (
    <div className="page grid-page">
      <Navigation active="/message" />
      <div className="message-content">
        <form className="message-form">
          <h3>მესიჯის გაგზავნა</h3>
          <textarea
            value={message}
            placeholder="მესიჯი"
            onChange={e => setMessage(e.target.value)}
          />
          <input
            type="number"
            value={amount}
            onChange={e => setAmount(e.target.value)}
            placeholder="მომხარებლების რაოდენობა"
          />
          <FaButton
            icon="faComments"
            className="big-font"
            onClick={sendMessage}
            type="submit"
          >
            გაგზავნა
          </FaButton>
        </form>
      </div>
    </div>
  );
};

export default Message;
