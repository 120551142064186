import React, { useState } from "react";
import { LoadButton } from "../../../util_comps/LoadButton";
import { swalert } from "../../../../utils/functions";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

export const StoreModal = ({append}) => {
  const [info, setInfo] = useState({
    name: "",
    brand: "",
    amount: 0,
    price: 0
  });
  const [loading, setLoading] = useState(false);
  const gold = useSelector(state => state.manager.gold);
  const addHandler = async () => {
    setLoading(true);
    const res = await fetch(`/api/v1/store/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: info.name,
        brand: info.brand,
        amount: Number(info.amount),
        gold,
        price: Number(info.price)
      }),
    });
    if (res.ok){ 
      append();
      swalert({
        icon: "success",
        text: "პროდუქტი წარმატებით დაემატა",
      });
    } else {
      swalert({
        icon: "error",
        text: "დაფიქსირდა შეცდომა",
      });
    }
  };
  const handler = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  return (
    <div className="modal-container">
      <h3>პროდუქტის დამატება</h3>
      <div className="modal-input">
        <span>დასახელება</span>
        <input type="text" name="name" value={info.name} onChange={handler} />
      </div>
      <div className="modal-input">
        <span>ფირმა</span>
        <input type="text" name="brand" value={info.brand} onChange={handler} />
      </div>
      <div className="modal-input">
        <span>რაოდენობა</span>
        <input
          type="number"
          min="0"
          name="amount"
          value={info.amount}
          onChange={handler}
        />
      </div>
      <div className="modal-input">
        <span>ფასი</span>
        <input
          type="number"
          min="0"
          name="price"
          value={info.price}
          onChange={handler}
        />
      </div>
      <LoadButton loading={loading} text="დამატება" onClick={addHandler} />
    </div>
  );
};

StoreModal.propTypes = {
  append: PropTypes.func.isRequired
}