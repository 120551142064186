import React, { useState } from "react";
import { Modal, AddModal } from "../modal/Modal";
import { Outsider } from "../../../util_comps/Wrappers";
import { Fade } from "../../../transitions/Fade";
import { proffesions } from "../../../../info";
import { useSelector } from "react-redux";
import { shortify } from "../../../../utils/functions";
import { post } from "../../../user/utils/utils";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Employee.css";
import { FaButton } from "../../../parts/FaButton";

export const Employee = ({
  name,
  isFree,
  services,
  profession,
  id,
  intern,
  refresh,
}) => {
  const [free, setFree] = useState(isFree);
  const [modal, setModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [edit, setEdit] = useState(false);
  const reports = useSelector(state => state.managerInfo.reports);
  const superAdmin = useSelector(state => state.manager.superAdmin);
  const MySwal = withReactContent(Swal);
  const delClick = () => {
    MySwal.fire({
      title: "დარწმუნებული ხართ?",
      text: "კადრი სამუდამოდ წაიშლება!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "გაუქმება",
      confirmButtonText: "კი",
    }).then(async result => {
      if (result.value) {
        const res = await fetch(`/api/v1/stylist/delete/${id}`);
        if (res.ok) {
          MySwal.fire("წაიშალა!", "კადრი წარმატებით წაიშალა.", "success");
          setDeleted(true);
        } else {
          MySwal.fire("დაფიქსირდა შეცდომა!", "სცადეთ ხელახლა.", "error");
        }
      }
    });
  };
  const editClick = () => {
    setEdit(true);
  };
  const freeHandler = async () => {
    const res = await post(`api/v1/stylist/update/manager/${id}`, {
      isFree: `${!free}`,
    });
    setFree(!free);
    if (!res.ok) {
      setFree(!free);
    }
  };
  const report = reports.find(item => item._id === id);
  return !deleted ? (
    <>
      {modal ? (
        <Outsider onOutside={() => setModal(false)}>
          <Modal
            name={name}
            profession={profession}
            services={services}
            onClose={() => setModal(false)}
            stylistId={id}
          />
        </Outsider>
      ) : null}
      {edit ? (
        <Outsider onOutside={() => setEdit(false)}>
          <AddModal
            update={true}
            id={id}
            preInfo={{
              name: name,
              profession: proffesions[profession],
              intern,
            }}
            preToSend={services}
            refresh={refresh}
          />
        </Outsider>
      ) : null}
      <div className="employee-item">
        <div className="employee-info-wrapper">
          <div className="employee-info">
            <div className="employee-name">
              <span>{name}</span>
            </div>
            <div>
              <span>
                {proffesions[profession]}&nbsp;{intern ? `(სტაჟიორი)` : ""}
              </span>
            </div>
            <div
              className={`employee-status ${free ? "free" : "not-free"}`}
              onClick={freeHandler}
            >
              <div className={`status-dot ${free ? "green" : "red"}`}></div>
              <span>{free ? "თავისუფალია" : "დაკავებულია"}</span>
            </div>
          </div>
          <div className="employee-right">
            <FaButton icon="faCashRegister" onClick={() => setModal(true)}>
              გადახდა
            </FaButton>
            <img
              src={require("./icons/arrow.svg")}
              className={`arrow ${expanded ? "arrow-y" : ""}`}
              alt="arrow"
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
        <Fade inprop={expanded} className="emp-down">
          <p>
            ხელფასი: {report?.totalEarned ? shortify(report?.totalEarned) : 0}₾
          </p>
          <div className="bottom-right">
            {superAdmin ? (
              <>
                <button className="primary-button del-btn" onClick={delClick}>
                  კადრის წაშლა
                </button>
                <button
                  className="primary-button edit-btn"
                  title="მონაცემების რედაქტირება"
                  onClick={editClick}
                >
                  &nbsp;
                </button>
              </>
            ) : null}
          </div>
        </Fade>
      </div>
    </>
  ) : null;
};

Employee.propTypes = {
  name: PropTypes.string.isRequired,
  isFree: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  profession: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  intern: PropTypes.bool.isRequired,
};
