import React from 'react';
import Slider from 'react-slick';
import { useFetch } from '../../utils/hooks';
import { settings } from '../lastWork/LastWork';
import './Widgets.css';

export const Widgets = () => {
    const { data } = useFetch("/api/v1/widget/get", []);

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1500,
        speed: 500,
        slidesToShow: Math.min(data.length, 4),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: Math.min(data.length, 3),
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: Math.min(data.length, 2),
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: Math.min(data.length, 1),
            },
          },
        ],
        slidesToScroll: 1,
      };

    return (
        <div className="widgets-container">
            <Slider {...settings}>
                {data.map((item, idx) => {
                    return (
                        <div className="widget-item" key={idx}>
                          <div className="widget-item-img-container">
                            <img src={`/api/v1/statics/widget/${item.photo}`} alt={item.text}/></div>
                            <span>{item.text}</span>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}