import React, { useState, useEffect } from "react";
import { TotalSwitch } from "../../pages/total/TotalSwitch";
import { SalaryItem } from "./SalaryItem";
import dayjs from "dayjs";
import { useDidUpdate } from "../utils/hooks";

export const StylistSalary = () => {
  const [details, setDetails] = useState({
    type: 0,
  });
  const [page, setPage] = useState({ r: false, page: 1 });
  const [loading, setLoading] = useState(false);
  const dayjsHelper = ["day", "week", "month"];
  const typeHelper = ["daily", "weekly", "monthly"];
  const [data, setData] = useState({ hasMore: true, reports: [] });
  const fetchMore = async (type, page) => {
    setLoading(true);
    const res = await fetch(
      `/api/v1/stylist/dashboard/salary?reportType=${typeHelper[type]}&page=${page}`
    );
    if (res.ok) {
      const info = await res.json();
      if (info.hasMore && info.reports.length === 0) {
        setData(prevState => ({
          hasMore: info.hasMore,
          reports: [
            ...prevState.reports,
            {
              totalEarned: 0,
              totalRecords: 0,
            },
          ],
        }));

        setLoading(false);
      }

      setLoading(false);
      setData(prevState => ({
        hasMore: info.hasMore,
        reports: [...prevState.reports, ...info.reports],
      }));
    }
  };
  useEffect(() => {
    fetchMore(details.type, page.page);
  }, [page]);
  useDidUpdate(() => {
    setData({ reports: [], hasMore: true });
    setPage({ page: 1, r: !page.r });
  }, [details]);
  return (
    <div className="stylist-salary-container dark">
      <TotalSwitch
        textes={["დღიური", "კვირეული", "თვიური"]}
        status={details.type}
        setStatus={x => setDetails({ ...details, type: x })}
      />
      {data.reports.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <h3 className="salary-header" style={{ color: "#fff" }}>
              {dayjs()
                .subtract(i, dayjsHelper[details.type])
                .format(details.type !== 0 ? "MM/YYYY" : "DD/MM/YYYY")}
            </h3>
            ;
            <SalaryItem
              key={i}
              records={item.totalRecords}
              totalEarned={item.totalEarned}
            />
          </React.Fragment>
        );
      })}
      {data.hasMore && !loading ? (
        <div className="centered">
          <button
            className="golden-button"
            onClick={() =>
              setPage(prevState => ({
                r: !prevState.r,
                page: prevState.page + 1,
              }))
            }
          >
            მეტის ჩატვირთვა
          </button>
        </div>
      ) : null}
    </div>
  );
};
