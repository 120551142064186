import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const StoreRecord = ({
  onChange,
  value,
  edit,
  selectValue,
  id,
  onSelectValueChange,
  index,
  delHandler
}) => {
  const store = useSelector(state => state.managerInfo.store);
  const onSelectChange = e => {
    const val = e.target.selectedOptions[0].getAttribute("data-val");
    onSelectValueChange(index, JSON.parse(val));
  };
  return (
    <div className="store-record">
      <select disabled={!edit} value={selectValue} onChange={onSelectChange}>
        {store.map((item, i) => {
          return (
            <option
              key={i}
              data-val={JSON.stringify({
                name: item.name,
                _id: item._id,
                brand: item.brand,
              })}
              value={item.name}
            >
              {item.name}
            </option>
          );
        })}
      </select>
      <input
        type="number"
        placeholder="გრამაჟი"
        disabled={!edit}
        value={value}
        onChange={e => onChange(id, e.target.value)}
      />
      <button onClick={()=>delHandler(id)} disabled={!edit}>წაშლა</button>
    </div>
  );
};

StoreRecord.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  edit: PropTypes.bool.isRequired,
  selectValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onSelectValueChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  delHandler: PropTypes.func.isRequired
};
