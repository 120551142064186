import React from "react";
import PropTypes from "prop-types";

export const TotalSwitch = ({ status, setStatus, textes, disabled = 3 }) => {
  return (
    <div className="cv-radios total">
      <div className={`cv-vip-container ${!status && "cv-vipped"}`}>
        {textes.map((item, i) => {
          return (
            <div
              key={i}
              onClick={() => (disabled === i ? () => {} : setStatus(i))}
              className={`cv-vip-item ${status === i && "cv-vipped-item"}`}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TotalSwitch.propTypes = {
  textes: PropTypes.array.isRequired,
  status: PropTypes.number.isRequired,
  setStatus: PropTypes.func.isRequired,
  disabled: PropTypes.number
};
