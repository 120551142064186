import React, { useState } from 'react';
import { useFetch } from '../../../utils/hooks';
import { FaButton } from '../../parts/FaButton';
import { Outsider } from '../../util_comps/Wrappers';
import { Navigation } from '../navigation/Navigation';
import { AddWidgetModal } from './AddWigetModal';
import { WidgetItem } from './WidgetItem';

export const Widgets = () => {
    const [addWidget, setAddWidget] = useState(false);
    const {data, refresh} = useFetch("/api/v1/widget/get", []);

    return (
        <div className="grid-page page widgets-page">
            <Navigation active="/widgets"/>
            <div>
                <div className="items-container-widget">
                {data.map((item, idx) => {
                    return (
                        <WidgetItem key={idx} name={item.text} id={item._id} photo={`/api/v1/statics/widget/${item.photo}`}/>
                    )
                })}
                </div>
                <div style={{textAlign: 'center', margin: '1em 0'}}>          
                    <FaButton onClick={()=>setAddWidget(true)}>დამატება</FaButton>
                </div>
            </div>
            {addWidget ? <Outsider onOutside={()=>setAddWidget(false)}>
                <AddWidgetModal closeHandler={()=>setAddWidget(false)} refresher={refresh}/>
            </Outsider> : null}
        </div>
    )
}