import React, { useState, useEffect } from "react";
import { NavBar } from "../../navbar/NavBar";
import { UserBooking } from "./UserBooking";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Footer } from "../../footer/Footer";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "../../styles/userBookings.css";

const UserBookings = ({ booking = true }) => {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [r, setR] = useState(false);
  const { type } = useSelector(state => state.user);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await fetch(
        booking ? `/api/v1/booking/fetch/${type}` : `/api/v1/${type}/visits`
      );
      if (res.ok) {
        setBookings(await res.json());
        setLoading(false);
      }
    })();
  }, [r]);
  const refresh = () => setR(!r);
  return (
    <div className="user-bookings">
      <NavBar />
      <div className="bookings">
        <div className="user-bookings-header">
          <h2>{booking ? "ჯავშნები" : "ისტორია"}</h2>
        </div>
        <div className="bookings-list responsive-grid">
          {bookings.map((item, i) => {
            return (
              <UserBooking
                key={i}
                stylist={item?.stylist?.name || item.stylist || item.user.name}
                service={item.service.name || item.service}
                gold={item.gold}
                booking={booking}
                price={item?.payment?.amount || item.price}
                date={item.bookedAt || item.createdAt}
                id={item._id}
                refresh={refresh}
              />
            );
          })}
        </div>
        {bookings.length === 0 && !loading ? (
          <div className="bookings-info">
            <FontAwesomeIcon icon={icons["faExclamationTriangle"]} size="2x" />
            <h1>{booking ? "ჯავშნები" : "ვიზიტები"} არ არის</h1>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

UserBookings.propTypes = {
  booking: PropTypes.bool.isRequired,
};

export default UserBookings;
