import React, { useState } from "react";
import phone from "../assets/phone.svg";
import mail from "../assets/mail.svg";
import location from "../assets/location.svg";
import contactImg from "../assets/contact.png";
import { NavBar } from "../navbar/NavBar";
import { Footer } from "../footer/Footer";
import { useTitle } from "../../../utils/hooks";
import { post } from "../utils/utils";
import Swal from "sweetalert2";
import "../styles/contact.css";

const Contact = () => {
  useTitle("კონტაქტი");
  const [inputs, setInputs] = useState({
    name: "",
    mail: "",
    message: "",
  });
  const onChange = e => {
    e.persist();
    setInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const sendHandler = async e => {
    e.preventDefault();
    const res = await post("/api/v1/user/contact", inputs);
    if (res.ok) {
      Swal.fire("მესიჯი წარმატებით გაიგზავნა", "", "success");
    }
  };
  return (
    <main className="contact">
      <NavBar />
      <div className="contact-header-wrapper">
        <div className="contact-header">
          <div className="contact-part">
            <img src={require("../assets/tree.svg")} />
          </div>
          <div className="contact-part">
            <h1>დაგვიკავშირდით</h1>
            <p>ჩვენ მზად ვართ ვუპასუხოთ თქვენს ნებისმიერ შეკითხვას</p>
          </div>
          <div className="contact-part">
            <img src={require("../assets/plant.svg")} />
          </div>
        </div>
      </div>
      <div className="contact-wrapper">
        <div className="contact-info-container">
          <div className="contact-info-img-container">
            <img src={contactImg} />
          </div>
          <div className="contact-info-details">
            <div className="contact-info-detail">
              <div className="contact-info-item-name">
                <h4>ელ-ფოსტა</h4>
              </div>
              <div className="online-services contact-info-item">
                <div className="online-services-img contact-icon">
                  <img src={mail} />
                </div>
                <p>info@goldi.ge</p>
              </div>
            </div>
            <div className="contact-info-detail">
              <div className="contact-info-item-name">
                <h4>ტელეფონი</h4>
              </div>
              <div className="contact-phone contact-info-item">
                <div className="contact-phone-img contact-icon">
                  <img src={phone} />
                </div>
                <p>+995 598 88 69 01</p>
              </div>
            </div>
            <div className="contact-info-detail">
              <div className="contact-info-item-name">
                <h4>მისამართი</h4>
              </div>
              <div className="contact-address contact-info-item">
                <div className="contact-address-img contact-icon">
                  <img src={location} />
                </div>
                <p>გორი სტალინის 20 / სტალინის 14 / სტალინის 2.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form-wrapper">
          <div className="contact-form-header">
            <h2>გამოგვიგზავნეთ მესიჯი</h2>
          </div>
          <div className="contact-form">
            <form>
              <div className="contact-input-container">
                <p>სახელი</p>
                <input
                  type="text"
                  name="name"
                  onChange={onChange}
                  className={`contact-input`}
                  placeholder="მარიამ ბერიანიძე"
                />
              </div>
              <div className="contact-input-container">
                <p>ელ-ფოსტა</p>
                <input
                  type="text"
                  name="email"
                  onChange={onChange}
                  className={`contact-input`}
                  placeholder="name@gmail.com"
                />
              </div>
              <div className="contact-input-container">
                <p>მესიჯი</p>
                <textarea
                  type="text"
                  name="message"
                  rows={8}
                  cols={250}
                  onChange={onChange}
                  className={`contact-input contact-textarea`}
                  placeholder="შაბათ-კვირის სამუშაო საათები მაინტერესებს!"
                ></textarea>
              </div>
              <div className="contact-form-btn">
                <button onClick={sendHandler}>გაგზავნა</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Contact;
