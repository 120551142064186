import React from "react";
import PropTypes from "prop-types";
import "./utils.css";

export const LoadButton = ({ loading, text, onClick, cn = "", ...rest }) => {
  return (
    <button
      className={`load-button primary-button ${cn} ${
        loading ? "loading-button" : ""
      }`}
      onClick={onClick}
      {...rest}
    >
      {text}
    </button>
  );
};

LoadButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  cn: PropTypes.string,
  resr: PropTypes.any,
};
