// Shorthands for common functions

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return undefined;
  }
};

export const scrollToTop = (timeout = 0) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, [timeout]);
};

export const post = async (api, body) => {
  try {
    const res = await fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res;
  } catch (e) {
    return { ok: false };
  }
};

export const get = async (api, err, defaultData = []) => {
  try {
    const res = await fetch(api);
    if (res.ok) {
      const data = await res.json();
      return data;
    }
    return defaultData;
  } catch (e) {
    return defaultData;
  }
};

export const isVisible = element => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= -50 &&
    rect.left >= -50 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const containsObject = (obj, list) => {
  for (let i = 0; i <= list.length; i++) {
    if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
      return true;
    }
  }
  return false;
};

export const toTop = () => window.scrollTo(0,0);