import React from "react";
import PropTypes from "prop-types";
import { proffesions } from "../../../info";

export const ProfSwitch = ({ value, onChange }) => {
  return (
    <div className="user-switch-container prof-switch">
      {proffesions.map((item,i) => {
        return (
          <div
            key={item}
            className={`user-switch-item ${
              value === i ? "user-switch-active" : ""
            }`}
            onClick={() => onChange(i)}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

ProfSwitch.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
