import React from "react";
import { NavBar } from "../../user/navbar/NavBar";
import "./Help.css";
import Pic1 from "../../../assets/1.jfif";
import Pic2 from "../../../assets/2.jfif";
import Pic3 from "../../../assets/3.jfif";
import Pic4 from "../../../assets/4.jfif";
import Pic5 from "../../../assets/5.jfif";

export const Help = () => {
  return (
    <div>
      <NavBar />
      <div className="halp-page">
        <h1>რეგისტრაცია</h1>
        <div className="halp-item">
          <img src={Pic5}></img>
          <p>
            მითითებულ ველში შეიყვანეთ თქვენი სახელი გვარი ტელეფონის ნომერი და
            პაროლი (პაროლი უნდა შეადგენდეს მინიმუმ 6 სიმბოლოს)
          </p>
        </div>
        <h1>აირჩიეთ სტილისტი</h1>
        <div className="halp-item">
          <img src={Pic2}></img>
          <p>
            1)აირჩიეთ სასურველი ფილიალი 2)აირჩიეთ სასურველი სტილისტი 3)შეამოწმეთ
            სტილისტის ნამუშევრები
          </p>
        </div>
        <h1>არჩეული სტილისტის დაჯავშნა</h1>
        <div className="halp-item">
          <img src={Pic1}></img>
          <p>1) დააკლიკეთ ღილაკს (დაჯავშნა)</p>
        </div>
        <h1>აირჩიეთ თარიღი</h1>
        <div className="halp-item">
          <img src={Pic3}></img>
          <p>
            1)აირჩიეთ თქვენთვის სასურველი სერვისი 2)აირჩიეთ თქვენთვის სასურველი
            დრო{" "}
          </p>
        </div>
        <h1>სამომხმარებლო პანელი</h1>
        <div className="halp-item">
          <img src={Pic4}></img>
          <p>
            სამომხმარებლო პანელში თქვენ შეგიძლიათ იხილოთ სრული ინფორმაცია თქვენი
            სალონში აქტივობის შესახებ
          </p>
        </div>
        <h1>ბონუს ქულა</h1>
        <div className="halp-item" id="bonus-points">
          <p>სალონში დახარჯული ერთი ლარი ისახება როგორც ერთი ბონუს ქულა.</p>
          <h5>500 ქულა--5% ქეშბექი(რომელსაც დახარჯავს პროდუქციის შესაძენად)</h5>
          <h5>
            1000 ქულა --10% ქეშბექი(რომელსაც დახარჯავს პროდუქციის შესაძენად)
          </h5>
          <h5>
            1500 ქულა--10% ქეშბექი(რომელსაც მოიხმარს ნებისმიერი პროდუქციის ან
            პროცედურის მისაღებად)
          </h5>
        </div>
      </div>
    </div>
  );
};
