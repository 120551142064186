import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const StylistAuthorized = ({ render, path }) => {
  const { isLogged, type } = useSelector(state => state.user);
  return isLogged && type === "stylist" ? (
    <Route exact path={path} render={render} />
  ) : (
    <Redirect to="/sign-in" />
  );
};

StylistAuthorized.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
