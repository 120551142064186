import React from "react";
import PropTypes from "prop-types";
import { Service } from "./Service";

export const AddServices = ({ services, handler }) => {
  // const [serv, setServ] = useState(services);
  // useEffect(()=>{
  //   setServ(services);
  //  console.log('services were changed');
  //}, [services])
  const onChange = (x, value) => {
    const clone = services.map(item => {
      if (item.name === x) {
        return {
          ...item,
          percentage: value,
        };
      }
      return item
    });
    handler(clone);
  };
  return (
    <div className="add-services">
      {services.length >= 1 ? <p>სერვისები</p> : null}
      {services.length >= 1
        ? services.map((item, i) => {
            return (
              <Service
                key={i}
                arr={services}
                onChange={onChange}
                name={item.name}
                price={item.price}
                perc={item.percentage}
              />
            );
          })
        : null}
    </div>
  );
};

AddServices.propTypes = {
  services: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired
};
