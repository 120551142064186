import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const SuperRoute = ({ render, path }) => {
  const { isLogged, superAdmin } = useSelector(state => state.manager);
  return isLogged && superAdmin ? (
    <Route exact path={path} render={render} />
  ) : (
    <Redirect to="/" />
  );
};

SuperRoute.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
