import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import x from "../assets/x.svg";

export const UserModal = ({ children, onClose, className="" }) => {
  const modalRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const closeHandler = () => {
    // Set opacity to 0 and unrender component after 300ms
    setOpacity(0);
    setTimeout(() => {
      onClose();
    }, 300);
  };
  const listener = e => {
    // If click was triggerred outside of modal
    if (!modalRef.current.contains(e.target)) {
      closeHandler();
    }
  };
  useEffect(() => {
    window.addEventListener("click", listener);
    // Remove event listener after unmounting component
    return () => window.removeEventListener("click", listener);
  }, []);
  return (
    <div className="user-modal-container" style={{ opacity }}>
      <div className={`user-modal ${className}`} ref={modalRef}>
        <img src={x} alt="X" className="user-modal-x" onClick={closeHandler} />
        {children}
      </div>
    </div>
  );
};

UserModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string
};
