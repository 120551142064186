import React from "react";
import { Link } from "react-router-dom";
import { CustomLink } from "../reusables/CustomLink";
import "../styles/footer.css";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-top">
        <div className="footer-top-part">
          <a href="https://www.xweb.ge" target="_blank" rel="noreferrer">
            <img src={require("../assets/xweb.png")} alt="xweb" />
          </a>
        </div>
        <div className="footer-top-part">
          <ul>
            <li>
              <CustomLink to="/user-services">სერვისები</CustomLink>
            </li>
            <li>
              <CustomLink to="/stylists">სპეციალისტები</CustomLink>
            </li>
            <li>
              <CustomLink to="/contact">კონტაქტი</CustomLink>
            </li>
          </ul>
        </div>
        <div className="footer-top-part">
          <ul>
            <li>
              <CustomLink to="/sign-in">ავტორიზაცია</CustomLink>
            </li>
            <li>
              <CustomLink to="/sign-up">რეგისტრაცია</CustomLink>
            </li>
            <li>
              <CustomLink to="/help">დახმარება</CustomLink>
            </li>
          </ul>
        </div>
        <div className="footer-top-part">
          <ul>
            <Link to="/terms">  
            <li>კონფიდენციალურობა</li>
            <li>წესები</li>
            <li>პირობები</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-part">2020 &copy; გოლდი</div>
        <div className="footer-bottom-part">
          Made By{" "}
          <a href="https://www.xweb.ge" target="_blank" rel="noreferrer">
            XWEB
          </a>
        </div>
      </div>
    </div>
  );
};
