import React, { useState } from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { Modal } from "../employees/modal/Modal";
import dayjs from "dayjs";
import { Outsider } from "../../util_comps/Wrappers";
import { NewTime } from "./NewTime";
import { post } from "../../user/utils/utils";
import { FaButton } from "../../parts/FaButton";
import { useSelector } from "react-redux";

export const Book = ({
  user,
  userNumber,
  stylist,
  manager,
  date,
  stylistId,
  service,
  services,
  type,
  serviceId,
  price,
  bookId,
  refresh,
  span,
}) => {
  const [modal, setModal] = useState(false);
  const [newTime, setNewTime] = useState(false);
  const { superAdmin, name } = useSelector(state => state.manager);
  const approveHandler = async () => {
    const res = await fetch(`/api/v1/booking/approve/${bookId}`);
    if (res.ok) {
      Swal.fire("დადასტურებულია", "", "success");
      refresh();
    }
  };
  const saveHandler = async x => {
    const res = await post(`/api/v1/booking/move/${bookId}`, {
      ...x,
      api: "move",
    });
    if (res.ok) {
      setNewTime(false);
      refresh();
    }
  };
  const deleteHandler = () => {
    Swal.fire({
      title: "დარწმუნებული ხართ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "კი",
      cancelButtonText: "გაუქმება",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await fetch(`/api/v1/booking/reject/${bookId}`);
        if (res.ok) {
          Swal.fire("წაშლილია!", "", "success");
          refresh();
        } else {
          Swal.fire("დაფიქსირდა შეცდომა!", "", "error");
        }
      }
    });
  };
  return (
    <>
      {modal ? (
        <Outsider onOutside={() => setModal(false)}>
          <Modal
            onClose={() => setModal(false)}
            stylistId={stylistId}
            services={services}
            preUser={{
              price: price,
              serviceid: serviceId,
              service: service,
              client: user,
              number: userNumber,
              deleteHandler: async () => {
                await fetch(`/api/v1/booking/reject/${bookId}?paid=true`);
                refresh();
              },
            }}
          />
        </Outsider>
      ) : null}
      <tr className="manager-book-container">
        <th>{manager}</th>
        <th>{user}</th>
        <th>{userNumber}</th>
        <th>{stylist}</th>
        <th>{service}</th>
        <th>{price}</th>
        <th className="th-flex">
          {!newTime || !superAdmin ? (
            <span onClick={() => setNewTime(true)}>
              {dayjs(date).format("DD/MM/YYYY HH:mm")}
              <br />
              {dayjs(date)
                .add(span * 30, "minutes")
                .format("DD/MM/YYYY HH:mm")}
            </span>
          ) : (
            <NewTime
              prevDate={date}
              stylistId={stylistId}
              span={span}
              onChange={() => {}}
              bookingId={bookId}
              onSave={saveHandler}
              onCancel={() => setNewTime(false)}
            />
          )}
          {type ? (
            <>
              <FaButton icon="faCashRegister" onClick={() => setModal(true)}>
                გადახდა
              </FaButton>
              {superAdmin && name.startsWith('ჯუნა') ? (
                <FaButton
                  icon="faTrash"
                  onClick={deleteHandler}
                  className="red-button"
                >
                  წაშლა
                </FaButton>
              ) : null}
            </>
          ) : (
            <>
              <FaButton icon="faCheckCircle" onClick={approveHandler}>
                დადასტურება
              </FaButton>
              <FaButton
                icon="faTimesCircle"
                onClick={deleteHandler}
                className="red-button"
              >
                გაუქმება
              </FaButton>
            </>
          )}
        </th>
      </tr>
    </>
  );
};

Book.propTypes = {
  user: PropTypes.string.isRequired,
  userNumber: PropTypes.string.isRequired,
  stylist: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  stylistId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  services: PropTypes.array.isRequired,
  bookId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  span: PropTypes.number.isRequired,
  manager: PropTypes.string.isRequired
};
