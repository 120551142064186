import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const Unauthorized = ({ render, path }) => {
  const {isLogged, name, superAdmin} = useSelector(state => state.manager);
  const adminName = 'ჯუნა შიუკაშვილი';
  const isBookManager = superAdmin && name !== adminName;
  return !isLogged ? (
    <Route
      exact
      path={path}
      render={render}
    />
  ) : (
    <Redirect to={isBookManager? "/manager/bookings":"/employees"} />
  );
};

Unauthorized.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
