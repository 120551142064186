import React from "react";
import PropTypes from "prop-types";

export const Hamburger = ({ open, toggle }) => {
  return (
    <div
      className={`hamburger-button ${
        open ? "hamburger-opened" : ""
      } user-hamburger`}
      onClick={() => toggle(!open)}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
