import React, { useEffect, useState, useRef } from "react";
import { NavBar } from "../navbar/NavBar";
import { useSelector } from "react-redux";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GalleryItem } from "../signleStylist/GalleryItem";
import { UploadPhoto } from "./UploadPhoto";
import { Footer } from "../footer/Footer";
import { useDidUpdate, useFetch, useTitle } from "../utils/hooks";
import { StylistNav } from "./StylistNav";
import { StylistStats } from "./StylistStats";
import { StylistSalary } from "./StylistSalary";
import { Toggle } from "../reusables/Toggle";
import Swal from "sweetalert2";
import "../styles/stylistProfile.css";

const StylistProfile = () => {
  useTitle("პროფილი");
  const { name, id } = useSelector(state => state.user);
  const [newPhoto, setNewPhoto] = useState("");
  const { data, refresh } = useFetch(`/api/v1/stylist/fetch/${id}`, {
    works: [],
    isFree: false,
  });
  const [nav, setNav] = React.useState(0);
  const [isFree, setIsFree] = React.useState(data.isFree);
  const fileRef = useRef(null);
  const onChange = async e => {
    const photo = new FormData();
    photo.append("photos", e.target.files[0]);
    const res = await fetch("/api/v1/stylist/dashboard/work/add", {
      method: "post",
      body: photo,
    });
    if (res.ok) {
      refresh();
    }
  };
  const deleteHandler = itemId => {
    Swal.fire({
      title: "დარწმუნებული ხართ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "კი",
      cancelButtonText: "გაუქმება",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await fetch(
          `/api/v1/stylist/dashboard/work/delete/${itemId}`
        );
        if (res.ok) {
          Swal.fire("წაშლილია!", "", "success");
          refresh();
        } else {
          Swal.fire("დაფიქსირდა შეცდომა!", "", "error");
        }
      }
    });
  };
  const modifiedWorks = data.works.map(item => {
    return {
      src: `/api/v1/statics/stylist/work/${id}/${item.work}`,
      id: item._id,
    };
  });
  const changePhoto = async e => {
    setNewPhoto(e.target.files[0]);
    const data = new FormData();
    data.append("photo", e.target.files[0]);
    const res = await fetch(`/api/v1/stylist/update/stylist`, {
      method: "post",
      body: data,
    });
    if (res.ok) {
      refresh();
    }
  };
  useDidUpdate(() => {
    (async () => {
      await fetch(`/api/v1/stylist/dashboard/status?isFree=${isFree ? 1 : 0}`);
    })();
  }, [isFree]);
  useEffect(() => {
    setIsFree(data.isFree);
  }, [data.isFree]);
  return (
    <div className="stylist-profile-page dark page">
      <NavBar />
      <input
        type="file"
        ref={fileRef}
        onChange={changePhoto}
        style={{ display: "none" }}
      />
      <div className="profile-header">
        <div className="profile-pic">
          <div className="profile-pic-wrapper">
            <img
              src={
                data.photo
                  ? `/api/v1/statics/stylist/photo/${id}`
                  : require("../assets/placeholder.png")
              }
              alt={name}
            />
            <div className="upload-pic" onClick={() => fileRef.current.click()}>
              <span>ფოტოს განახლება</span>
              <FontAwesomeIcon icon={faCamera} />
            </div>
          </div>
          <div className="profile-name">
            <h1>{name}</h1>
            <div className="toggle-wrapper">
              <span style={{ color: !isFree ? "red" : "#ffffff" }}>
                დაკავებული
              </span>
              <Toggle value={!isFree} onChange={() => setIsFree(!isFree)} />
            </div>
          </div>
        </div>
      </div>
      <StylistNav value={nav} onChange={setNav} />
      {nav === 0 ? (
        <div className="responsive-grid pics-grid">
          <UploadPhoto value="" onChange={onChange} />
          {modifiedWorks.map((item, i) => {
            return (
              <div className="gallery-item-wrapper" key={i}>
                <GalleryItem
                  image={item.src}
                  images={modifiedWorks.map(item => item.src)}
                />
                <img
                  src={require("../assets/trash.svg")}
                  alt="cancel"
                  title="გაუქმება"
                  className="delete-book"
                  onClick={() => deleteHandler(item.id)}
                />
              </div>
            );
          })}
        </div>
      ) : null}
      {nav === 1 ? <StylistStats /> : null}
      {nav === 2 ? <StylistSalary /> : null}
      <Footer />
    </div>
  );
};

export default StylistProfile;
