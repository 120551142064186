import { USER_LOGIN, USER_LOGOUT } from "../actions/actiontypes";
import {
  setLocalStorage,
  getLocalStorage,
} from "../components/user/utils/utils";

const initialState = getLocalStorage("user")
  ? {
      ...getLocalStorage("user"),
    }
  : {
      name: "",
      number: "",
      id: "",
    };

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      setLocalStorage("user", {
        ...action.payload,
        isLogged: true,
      });
      return {
        ...state,
        ...action.payload,
        isLogged: true,
      };
    case USER_LOGOUT:
      setLocalStorage("user", {});
      return {
        name: "",
        number: "",
        id: "",
      };
    default:
      return state;
  }
};

export default mainReducer;
