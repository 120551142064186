import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { swalert } from "../../../../utils/functions";
import { useDidUpdate } from "../../../../utils/hooks";
import { useSelector } from "react-redux";
import { FaButton } from "../../../parts/FaButton";
import { Outsider } from "../../../util_comps/Wrappers";
import { Input } from "../../../user/reusables/Input";

export const StoreItem = ({ amount, name, id, brand, gold, price }) => {
  const [info, setInfo] = useState({
    amount,
    name,
    id,
    brand,
    price,
  });
  const [sellAmount, setSellAmount] = useState(0);
  const [sellModal, setSellModal] = useState(false);

  const superAdmin = useSelector((state) => state.manager.superAdmin);
  const MySwal = withReactContent(Swal);
  const [edit, setEdit] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const handler = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const wasChanged = useRef(false);
  useDidUpdate(() => {
    wasChanged.current = true;
  }, [info]);
  const editHandler = async () => {
    const res = await fetch(`/api/v1/store/update/${id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: Number(info.amount),
        name: info.name,
        brand: info.brand,
        gold,
        price: Number(info.price),
      }),
    });
    if (res.ok) {
      swalert({
        icon: "success",
        text: "ჩანაწერი წარმატებით განახლდა",
      });
    }
  };
  useDidUpdate(() => {
    if (!edit && wasChanged) {
      editHandler();
    }
  }, [edit]);
  const deleteHandler = async () => {
    MySwal.fire({
      title: "დარწმუნებული ხართ?",
      text: "ჩანაწერი სამუდამოდ წაიშლება!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "გაუქმება",
      confirmButtonText: "კი",
    }).then(async (result) => {
      if (result.value) {
        const res = await fetch(`/api/v1/store/delete/${id}`);
        if (res.ok) {
          MySwal.fire("წაიშალა!", "ჩანაწერი წარმატებით წაიშალა.", "success");
          setDeleted(true);
        } else {
          MySwal.fire("დაფიქსირდა შეცდომა!", "სცადეთ ხელახლა.", "error");
        }
      }
    });
  };

  const addStoreRecord = async () => {
    const res = await fetch("/api/v1/store/record/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        itemId: id,
        amount: Number(sellAmount),
      }),
    });

    if (res.ok) {
      Swal.fire({
        icon: "success",
        title: "გაყიდვა წარმატებით განხორციელდა",
      });
      setSellModal(false);
    }
  };

  return deleted ? null : (
    <>
      {sellModal ? (
        <Outsider onOutside={() => setSellModal(false)}>
          <div style={{ padding: "3em" }}>
            <Input
              placeholder="რაოდენობა"
              icon="faCalculator"
              value={sellAmount}
              onChange={(ev) => setSellAmount(ev.target.value)}
              type="number"
            />
            <div style={{ color: "white", width: "100px" }}>
              {sellAmount * info.price}₾
            </div>
            <FaButton onClick={addStoreRecord}>გაყიდვა</FaButton>
          </div>
        </Outsider>
      ) : null}
      <tr className="record-tr">
        <td>
          {edit ? (
            <input name="name" value={info.name} onChange={handler} />
          ) : (
            info.name
          )}
        </td>
        <td>
          {edit ? (
            <input name="amount" value={info.amount} onChange={handler} />
          ) : (
            info.amount
          )}
        </td>{" "}
        <td>
          {edit ? (
            <input name="price" value={info.price} onChange={handler} />
          ) : (
            info.price
          )}
        </td>
        <td className="flex-td">
          {edit ? (
            <input name="brand" value={info.brand} onChange={handler} />
          ) : (
            info.brand
          )}
          <div>
            <FaButton onClick={() => setSellModal(true)}>გაყიდვა</FaButton>
            {superAdmin ? (
              <>
                <img
                  src={
                    edit
                      ? require("./save.svg")
                      : require("../../records/record/edit.svg")
                  }
                  alt="edit"
                  className="tr-edit"
                  onClick={() => setEdit(!edit)}
                />
                <img
                  src={require("../../records/record/delete.svg")}
                  alt="delete"
                  className="tr-delete"
                  onClick={deleteHandler}
                />
              </>
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
};

StoreItem.propTypes = {
  id: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  gold: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
};
