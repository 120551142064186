import React from "react";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ManagerLoggedRoute } from "./LoggedRoute";
import { Unauthorized } from "./Unauthorized";
import { SuperRoute } from "./SuperRoute";
import Login from "../components/pages/login/Login";
import Employees from "../components/pages/employees/Employees";
import Services from "../components/pages/services/Services";
import Managers from "../components/pages/managers/Managers";
import Records from "../components/pages/records/Records";
import Users from "../components/pages/users/Users";
import Store from "../components/pages/store/Store";
import Reports from "../components/pages/reports/Reports";
import PassChange from "../components/pages/passchange/PassChange";
import Total from "../components/pages/total/Total";
import Landing from "../components/user/landing/Landing";
import Contact from "../components/user/contact/Contact";
import { UserUnauthorized } from "./UserUnauthorized";
import Auth from "../components/user/auth/Auth";
import NotFound from "../components/user/smallPages/NotFound";
import SignleStylist from "../components/user/signleStylist/SignleStylist";
import Stylists from "../components/user/stylists/Stylists";
import { UserAuthorized } from "./UserAuthorized";
import UserBookings from "../components/user/userDash/UserBooking/UserBookings";
import Profile from "../components/user/userDash/Profile/Profile";
import StylistProfile from "../components/user/stylistDash/StylistProfile";
import ServicesPage from "../components/user/servicesPage/ServicesPage";
import { StylistAuthorized } from "./StylistAuthorized";
import Books from "../components/pages/books/Books";
import { UserOrStylistRoute } from "./UserOrStylistRoute";
import Forgot from "../components/user/forgot/Forgot";
import StylistVisits from "../components/user/stylistDash/StylistVisits";
import Message from "../components/pages/message/Message";
import { Widgets } from "../components/pages/widgets/Widgets";
import { Terms } from "../components/pages/terms/Terms";
import { Help } from "../components/pages/help/Help";
import { Sales } from "../components/pages/sales/Sales";

export const Routing = () => {
  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={300} classNames="fade">
            <Switch location={location}>
              <Unauthorized
                exact
                path="/admin"
                render={() => {
                  return <Login />;
                }}
              />
              <Route
                exact
                path="/"
                render={() => {
                  return <Landing />;
                }}
              />
              <Route
                exact
                path="/terms"
                render={() => {
                  return <Terms />;
                }}
              />
              <Route
                exact
                path="/help"
                render={() => {
                  return <Help />;
                }}
              />
              <Route
                exact
                path="/contact"
                render={() => {
                  return <Contact />;
                }}
              />
              <ManagerLoggedRoute
                path="/employees"
                render={() => {
                  return <Employees />;
                }}
              />
              <ManagerLoggedRoute
                path="/services"
                render={() => {
                  return <Services />;
                }}
              />
              <SuperRoute
                path="/managers"
                render={() => {
                  return <Managers />;
                }}
              />
              <ManagerLoggedRoute
                path="/records"
                render={() => {
                  return <Records />;
                }}
              />
              <ManagerLoggedRoute
                path="/users"
                render={() => {
                  return <Users />;
                }}
              />
              <ManagerLoggedRoute
                path="/change-password"
                render={() => {
                  return <PassChange />;
                }}
              />
              <ManagerLoggedRoute
                path="/store"
                render={() => {
                  return <Store />;
                }}
              />
              <ManagerLoggedRoute
                path="/sales"
                render={() => {
                  return <Sales />;
                }}
              />
              <SuperRoute
                path="/reports"
                render={() => {
                  return <Reports />;
                }}
              />
              <ManagerLoggedRoute
                path="/total"
                render={() => {
                  return <Total />;
                }}
              />
              <ManagerLoggedRoute
                path="/widgets"
                render={() => {
                  return <Widgets />;
                }}
              />
              <ManagerLoggedRoute
                path="/manager/bookings"
                render={() => {
                  return <Books />;
                }}
              />
              <UserUnauthorized
                path="/sign-up"
                render={() => {
                  return <Auth signup={true} />;
                }}
              />
              <UserUnauthorized
                path="/sign-in"
                render={() => {
                  return <Auth signup={false} />;
                }}
              />
              <SuperRoute
                path="/message"
                render={() => {
                  return <Message />;
                }}
              />
              <Route
                path="/stylist/:id"
                render={({ match }) => {
                  return <SignleStylist id={match.params.id} />;
                }}
              />
              <Route
                path="/user-services"
                render={() => {
                  return <ServicesPage />;
                }}
              />
              <Route
                path="/stylists"
                render={() => {
                  return <Stylists />;
                }}
              />
              <UserOrStylistRoute
                path="/bookings"
                render={() => {
                  return <UserBookings booking={true} />;
                }}
              />
              <UserOrStylistRoute
                path="/history"
                render={() => {
                  return <UserBookings booking={false} />;
                }}
              />
              <UserAuthorized
                path="/profile"
                render={() => {
                  return <Profile />;
                }}
              />
              <ManagerLoggedRoute
                path="/manager/profile/:id"
                render={({ match }) => {
                  return <Profile id={match.params.id} />;
                }}
              />
              <Unauthorized
                path="/recovery"
                render={() => {
                  return <Forgot />;
                }}
              />
              <StylistAuthorized
                path="/dashboard/profile"
                render={() => {
                  return <StylistProfile />;
                }}
              />
              <StylistAuthorized
                path="/dashboard/visits"
                render={() => {
                  return <StylistVisits />;
                }}
              />
              <Route
                path="/"
                render={() => {
                  return <NotFound />;
                }}
              />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    />
  );
};
