import React from "react";
import loading from "./icons/loading.svg";
import error from "./icons/error.svg";
import './styles.css';

export const Loading = () => {
  return (
    <div className="loading-container part">
      <img src={loading} alt="loading" />
    </div>
  );
};

export const Error = () => {
  return (
    <div className="error-container part">
      <img src={error} alt="Error" />
      <h3>დაფიქსირდა შეცდომა</h3>
    </div>
  );
};
