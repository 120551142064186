import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserModal } from "../../../user/reusables/UserModal";
import { useSelector } from "react-redux";
import { hours } from "../../../../info";
import { containsObject, post } from "../../../user/utils/utils";
import { useFetch } from "../../../user/utils/hooks";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { FaButton } from "../../../parts/FaButton";

export const getDifference = (time, endTime) => {
  const date1 = dayjs(`2/2/2020 ${time}`);
  const date2 = dayjs(`2/2/2020 ${endTime}`);
  const mins = date2.diff(date1, "minute");
  return parseInt(mins / 30);
};

export const AddBook = ({ name, onClose, number, refresh }) => {
  const gold = useSelector(state => state.manager.gold);
  const services = useSelector(state => state.services);
  const { employees } = useSelector(state => state.managerInfo);
  const firstStylist = employees.filter(item => item.gold === gold)[0];
  const [bookInfo, setBookInfo] = useState({
    stylist: firstStylist,
    service: services.filter(
      item => item.profession === firstStylist.profession
    )[0],
    bookedAtIndex: 0,
    bookedDate: dayjs().format("YYYY-MM-DD"),
    time: "05:00",
    endTime: "05:30",
    client: {
      name: name,
      number: number,
    },
  });
  const { data: freeTimes } = useFetch(
    `/api/v1/booking/free/${bookInfo.stylist._id}?date=${bookInfo.bookedDate}`,
    []
  );

  useEffect(()=>{
    setBookInfo(prevState => ({
      ...prevState,
      service: services.filter(
        item => item.profession === prevState.stylist.profession
      )[0],
    }))
  },[bookInfo.stylist, services])

  const clientChange = e => {
    e.persist();
    setBookInfo(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const inputChange = e => {
    e.persist();
    setBookInfo(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const bookHandler = async () => {
    const res = await post(`/api/v1/booking/add/manager`, {
      stylistId: bookInfo.stylist._id,
      serviceId: bookInfo.service._id,
      bookedAtIndex: Number(bookInfo.bookedAtIndex),
      bookedDate: bookInfo.bookedDate,
      span: getDifference(bookInfo.time, bookInfo.endTime),
      client: bookInfo.client,
      gold,
    });
    if (res.ok) {
      Swal.fire("ჯავშანი დამატებულია", "", "success");
      refresh && refresh();
    } else {
      Swal.fire("დაფიქსრიდა შეცდომა", "", "error");
    }
  };

  const toObj = x => JSON.parse(x);
  const selectChange = e => {
    e.persist();
    setBookInfo(prevState => ({
      ...prevState,
      [e.target.name]: toObj(
        e.target.selectedOptions[0].getAttribute(`data-${e.target.name}`)
      ),
    }));
  };

  return (
    <UserModal onClose={onClose} className="dark-modal">
      <h1 style={{ textAlign: "center", color: "#fff" }}>ჯავშნის დამატება</h1>
      <div className="light-bg">
        <div>
          <span>სახელი: </span>
          <input
            type="text"
            name="name"
            value={bookInfo.client.name}
            onChange={clientChange}
          />
        </div>
        <div>
          <span>ნომერი: </span>
          <input
            type="text"
            name="number"
            value={bookInfo.client.number}
            onChange={clientChange}
          />
        </div>
        <div>
          <span>სტილისტი: </span>
          <select
            name="stylist"
            onChange={selectChange}
            value={bookInfo.stylist.name}
          >
            {employees
              .filter(item => item.gold === gold)
              .map((item, i) => {
                return (
                  <option key={i} data-stylist={JSON.stringify(item)}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div>
          <span>სერვისი: </span>
          <select
            name="service"
            onChange={selectChange}
            value={bookInfo.service.name}
          >
            {services
              .filter(item => item.profession === bookInfo.stylist.profession)
              .map((item, i) => {
                return (
                  <option key={i} data-service={JSON.stringify(item)}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div>
          <span>თარიღი: </span>
          <input
            onChange={inputChange}
            name="bookedDate"
            type="date"
            value={bookInfo.bookedDate}
          />
        </div>
        <div>
          <span>დრო: </span>
          <select
            value={bookInfo.time}
            name="time"
            onChange={e => {
              setBookInfo({
                ...bookInfo,
                time: e.target.value,
                bookedAtIndex: e.target.selectedOptions[0].getAttribute(
                  "data-index"
                ),
              });
            }}
          >
            {Object.keys(hours).map((item, i) => {
              let obj = hours[item];
              let hour = obj.h.toString().length === 1 ? `0${obj.h}` : obj.h;
              let min = obj.m.toString().length === 1 ? `0${obj.m}` : obj.m;
              console.log(`${hour}:${min}`, item);
              return containsObject(obj, freeTimes) ? (
                <option key={item} data-index={i}>{`${hour}:${min}`}</option>
              ) : null;
            })}
          </select>
        </div>
        <div>
          <span>დამთავრების დრო: </span>
          <select
            value={bookInfo.endTime}
            name="time"
            onChange={e => {
              setBookInfo({
                ...bookInfo,
                endTime: e.target.value,
              });
            }}
          >
            {Object.keys(hours).map((item,i) => {
              let obj = hours[item];
              let hour = obj.h.toString().length === 1 ? `0${obj.h}` : obj.h;
              let min = obj.m.toString().length === 1 ? `0${obj.m}` : obj.m;
              return (
                <option key={i} data-index={i}>{`${hour}:${min}`}</option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="centered">
        <FaButton icon="faBookmark" className="big-font" onClick={bookHandler}>
          დაჯავშნა
        </FaButton>
      </div>
    </UserModal>
  );
};

AddBook.propTypes = {
  name: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  refresh: PropTypes.func,
};
