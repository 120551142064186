import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LOG_IN } from "../../../actions/actiontypes";
import { swalert, setLocalStorage } from "../../../utils/functions";
import { LoadButton } from "../../util_comps/LoadButton";
import { useTitle } from "../../../utils/hooks";
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState({
    name: "",
    password: "",
    gold: 0,
  });
  const [loading, setLoading] = useState(false);
  useTitle("ავტორიზაცია");
  const changeHandler = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const switchChoose = x => {
    setInfo({ ...info, gold: x });
  };
  const loginHandler = async e => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch("/api/v1/auth/manager/sign-in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    });
    if (res.ok) {
      const data = await res.json();
      setLoading(false);
      dispatch({
        type: LOG_IN,
        payload: {
          isLogged: true,
          ...data,
        },
      });
      setLocalStorage("manager", {
        ...data,
        superAdmin: data.managerStatus === "normal" ? false : true,
        isLogged: true,
      });
    } else {
      setLoading(false);
      swalert({
        icon: "error",
        text: "სახელი ან პაროლი არასწორია",
      });
    }
  };
  return (
    <div className="login-page page">
      <form className="login-form">
        <h2>ავტორიზაცია</h2>
        <input
          type="text"
          name="name"
          onChange={changeHandler}
          className="login-input"
          placeholder="სახელი"
        />
        <input
          type="password"
          name="password"
          onChange={changeHandler}
          className="login-input"
          placeholder="პაროლი"
        />
        <div className="gold-switch">
          <div
            className={`gold-switch-item ${
              info.gold === 0 ? "gold-chosen" : ""
            }`}
            onClick={() => switchChoose(0)}
          >
            <span>გოლდი 1</span>
          </div>
          <div
            className={`gold-switch-item ${
              info.gold === 1 ? "gold-chosen" : ""
            }`}
            id="1"
            onClick={() => switchChoose(1)}
          >
            <span>გოლდი 2</span>
          </div>
          <div
            className={`gold-switch-item ${
              info.gold === 2 ? "gold-chosen" : ""
            }`}
            id="2"
            onClick={() => switchChoose(2)}
          >
            <span>გოლდი 3</span>
          </div>
        </div>
        <LoadButton
          type="submit"
          onClick={loginHandler}
          loading={loading}
          text="შესვლა"
          cn="login-btn"
        />
      </form>
    </div>
  );
};

export default Login;
