import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const ManagerLoggedRoute = ({ render, path }) => {
  const isLogged = useSelector(state => state.manager.isLogged);
  return isLogged ? (
    <Route
      exact
      path={path}
      render={render}
    />
  ) : (
    <Redirect to="/" />
  );
};

ManagerLoggedRoute.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
