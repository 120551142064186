import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Hamburger } from "./Hamburger";
import { User } from "./user/User";
import "../styles/navbar.css";
import { toTop } from "../utils/utils";

export const NavBar = () => {
  const userIsLogged = useSelector(state => state.user.isLogged);
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const scrollListener = () => {
      // Get scroll distance from the top
      const scrollNum = document.documentElement.scrollTop;
      // If distance is gte 100
      if (scrollNum >= 100) {
        if (!scrolled) setScrolled(true);
      } else {
        if (scrolled) setScrolled(false);
      }
    };
    window.addEventListener("scroll", scrollListener);
    // Remove event listener after unmounting the component
    return () => window.removeEventListener("scroll", scrollListener);
  });
  // Navigation bar content
  const navItems = [
    {
      name: "მთავარი",
      href: "/",
    },
    {
      name: "სერვისები",
      href: "/user-services",
    },
    {
      name: "სპეციალისტები",
      href: "/stylists",
    },
    {
      name: "კონტაქტი",
      href: "/contact",
    },
  ];
  return (
    <>
      <Hamburger open={open} toggle={setOpen} />
      <div className={`resp-logo ${scrolled ? "user-nav-scrolled" : ""}`}>
        <Link to="/" onClick={toTop}>
          <img src={require("../assets/logo.png")} alt="gold" />
        </Link>
      </div>
      {window.innerWidth <= 768 ? (
        <style>{`#root{padding-top: 50px;}`}</style>
      ) : null}
      <div
        className={`user-nav-bar ${open ? "user-nav-open" : ""} ${
          scrolled ? "user-nav-scrolled" : ""
        }`}
      >
        <div className="user-nav-bar-logo">
          <Link to="/" onClick={toTop}>
            <img src={require("../assets/logo.png")} alt="gold" />
          </Link>
        </div>
        <div className="user-nav-bar-ul">
          <ul>
            {navItems.map((item, i) => {
              return (
                <li key={i}>
                  <Link to={item.href}>{item.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="user-nav-bar-signup">
          {userIsLogged ? (
            <User />
          ) : (
            <>
              <span className="link-2" onClick={toTop}>
                <Link to="/sign-in">შესვლა</Link>
              </span>
              <Link to="/sign-up" onClick={toTop}>
                <button>რეგისტრაცია</button>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};
