import React from "react";
import "./utils.css";

export const Loading = () => {
  return (
    <div className="loading-container">
      <img src={require("./icons/loading2.svg")} alt="loading..." />
    </div>
  );
};

export const Error = () => {
  return (
    <p style={{ textAlign: "center", color: "red" }}> დაფიქსირდა შეცდომა</p>
  );
};
